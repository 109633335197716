import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import RSelect from 'react-select';
import { assignBrokerToProducerReset, assign_broker_to_producer, getSpecificEntityProfileListReset, retrieve_active_entity_profile_list } from '../../utils/actions';
import {
    rSelectMapFun,
    retreive_rSelectVariables,
} from '../../utils/functionalUtils';
import * as app_consts from '../../utils/constants';

const initialState = { producer_id: "", member_type_id: "" };

const AssignBrokerToProducerModal = ({ isOpen, setIsOpen, successFunction, producer_id, producer_name }) => {

    const dispatch = useDispatch();

    const [openModal, setOpenModal] = useState(isOpen);
    const [isLoading, setLoading] = useState("");
    const [error, setError] = useState("");
    const [brokers_list, setBrokersList] = useState([]);
    const [broker_list_loading, setBrokerListLoading] = useState(false);
    const [producer_det, setproducerDet] = useState(producer_id);
    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        setOpenModal(isOpen);
        if (isOpen) 
        {
            setError("");
            formik.resetForm();
            dispatch(retrieve_active_entity_profile_list("broker"));
            setFormData({ ...formData, ["producer_id"]: producer_id });
        }
    }, [isOpen]);

    // Listen to Active Producer List Retrieve
    const entity_list_state = useSelector((state) => state.specificEntityProfileList);
    // 
    useEffect(() => {
        if (entity_list_state.status !== app_consts.IDLE)
        {
            if (entity_list_state.isLoading)
            {
                setBrokerListLoading(true);
            }
            else
            {
                setBrokerListLoading(false);
                const fetchData = async () => {
                    if (entity_list_state.data)
                    {
                        let mappedData = [];
                        await Promise.all(
                            entity_list_state?.data?.map(async (data_to_map) => {
                                const { label, value } = retreive_rSelectVariables(app_consts.ACTIVE_BROKER_LIST_MAP, data_to_map);
                                const result = await rSelectMapFun(label, value);
                                mappedData.push(result);
                            })
                        );
                        setBrokersList(mappedData);
                    }
                };

                fetchData();
                dispatch(getSpecificEntityProfileListReset());
            }
        }
    }, [entity_list_state]);

    const assignBrokerParams = yup.object().shape({
        member_type_id: yup.string().required('Broker is Required'),
        producer_id: yup.string().required('Producer is Required')
    });

    const formik = useFormik({
        initialValues: {
            member_type_id: "",
            producer_id: ""
        },
        validationSchema: assignBrokerParams,


        onSubmit: async (values) => {
            setError("");
            const assign_data = { member_type_id: values.member_type_id, producer_id: producer_det };
            try
            {
                await dispatch(assign_broker_to_producer(assign_data));

            } catch (error)
            {
                console.log(error.message);
            }

        },
    });

    // Listen to State Changes
    const assignBrokerToProducer = useSelector((state) => state.assignBrokerToProducer);
    // 
    useEffect(() => {
        if (assignBrokerToProducer.status !== app_consts.IDLE)
        {
            if (assignBrokerToProducer.isLoading)
            {
                setLoading(true);
            }
            else
            {
                setLoading(false);
                if (assignBrokerToProducer.error)
                {
                    setError(assignBrokerToProducer.error);
                }
                else
                {
                    closeModal();
                    successFunction(assignBrokerToProducer.data);
                }
                dispatch(assignBrokerToProducerReset());
            }
        }
    }, [assignBrokerToProducer]);

    const closeModal = () => {
        setIsOpen(false);
    };

    // Handle Changes in React Select Field
    const handlRSelectChanges = (option, name) => {
        setFormData({ ...formData, [name]: option.value });
    };

    const handleSubmit = () => {
        setError("");
        dispatch(assign_broker_to_producer({ producer_id: formData.producer_id, member_type_id: formData.member_type_id }));
    };

    return (
        <>
            {
                openModal && (
                    <>
                        <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
                            <div className="fixed inset-0 bg-black opacity-50"></div>
                            {/* Modal content goes here */}
                            <div className="my-8 relative p-4 bg-white lg:w-4/12 w-5/6 rounded-lg shadow dark:bg-gray-800 md:p-8 max-h-screen overflow-y-auto">
                                {/* Add your modal content here */}
                                <div class="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                                        Assign Broker to Producer
                                    </h3>
                                    <button type="button" onClick={closeModal} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="updateProductModal">
                                        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                        <span class="sr-only">Close</span>
                                    </button>
                                </div>
                                <form className="space-y-4 md:space-y-6" onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                    <div>
                                        <label
                                            htmlFor='bid_total'
                                            className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                                        >
                                            Producer
                                        </label>
                                        <input
                                            type='text'
                                            name='bid_total'
                                            value={producer_id && producer_name && (`${producer_id} - ${producer_name}`)}
                                            disabled
                                            className='bg-gray-200 cursor-not-allowed border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                                        />
                                    </div>
                                    {formik.touched.producer_id && formik.errors.producer_id && (
                                        <p className="text-red-500 text-sm mt-1">{formik.errors.producer_id}</p>
                                    )}
                                    <div>
                                        <label
                                            htmlFor='member_type_id'
                                            className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                                        >
                                            Broker
                                        </label>
                                        <RSelect
                                            name='member_type_id'
                                            // value={formik.values.member_type_id}
                                            onChange={(e) =>
                                                handlRSelectChanges(e, 'member_type_id')
                                            }
                                            options={brokers_list}
                                            isLoading={broker_list_loading}
                                        />
                                    </div>
                                    {formik.touched.member_type_id && formik.errors.member_type_id && (
                                        <p className="text-red-500 text-sm mt-1">{formik.errors.member_type_id}</p>
                                    )}
                                    <div className='flex justify-end items-end'>
                                        <button
                                            type='submit'
                                            disabled={isLoading} // Added formik.isSubmitting to disable the button when submitting
                                            className='cursor text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-primary-800 mr-2 mb-2'
                                        >
                                            {isLoading ? 'Assigning...' : 'Assign'}
                                        </button>
                                    </div>
                                    {error && (
                                        <div
                                            className='p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400'
                                            role='alert'
                                        >
                                            <span className='font-medium'>Error: </span>
                                            {error}
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
};

export default AssignBrokerToProducerModal;