import React from 'react';

const VerticalCustomTabs = ({ tabs, activeTab, setActiveTab }) => {
 return (
  <div className="text-sm font-medium text-gray-500 border-l border-gray-200 dark:text-gray-400 dark:border-gray-700">
   <ul className="flex-column flex-wrap -mb-px">
    {tabs.map((tab) => (
     <li key={tab.title} className="mr-2">
      <a
       href="#"
       className={`inline-block p-4 border-l-2 text-lg ${activeTab === tab.title
        ? 'border-green-600 text-green-600 dark:border-green-500'
        : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
        } ${tab.disabled ? 'cursor-not-allowed text-gray-400 dark:text-gray-500' : ''}`}
       onClick={(e) => {
        e.preventDefault();
        if (!tab.disabled)
        {
         setActiveTab(tab.title);
        }
       }}
       aria-current={activeTab === tab.title ? 'page' : undefined}
      >
       {tab.title}
      </a>
     </li>
    ))}
   </ul>
  </div>
 );
};

export default VerticalCustomTabs;
