import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Logo from '../../../components/Logo';
import NavLogo from '../../../components/NavLogo';
import ChildHeaderBanner from '../../../layouts/ChildHeaderBanner';
import CustomTabs from '../../../layouts/CustomTabs';
import { useDispatch, useSelector } from 'react-redux';
import { getProducer, getEntity } from '../../../utils/actions';
import DocumentViewer from '../../../layouts/DocumentViewer';
import * as app_consts from '../../../utils/constants';
import UpdateBrokerClientModal from '../../../components/modals/UpdateBrokerClientModal';
import AddProducerBankDetailsModal from '../../../components/modals/AddProducerBankDetailsModal';

const CustomerDetails = () => {

    const [activeTab, setActiveTab] = useState('Contact Information');

    const { id, entity_mid, entity_id, view } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const producer = useSelector((state) => state.getProducer.data);
    const entity = useSelector((state) => state.getEntity.data);
    const [loading, setLoading] = useState(false);


    const [modal, setModal] = useState({
        customerModal: false,
        individual: false,
        organization: false,
    });

    const closeCustomerModal = () => {
        setModal({ ...modal, customerModal: false });
    };

    const openSelectedModal = (modalToBeOpened) => {
        setModal({ ...modal, [modalToBeOpened]: true });
    };

    const [bankModal, setBankModal] = useState({
        producerBankModal: false,
        individual: false,
        organization: false,
    });

    const closeBankModal = () => {
        setBankModal({ ...bankModal, producerBankModal: false });
    };

    const openSelectedBankModal = (bankModalToBeOpened) => {
        setBankModal({ ...bankModal, [bankModalToBeOpened]: true });
    };

    useEffect(() => {
        const fetchProducerAndEntity = async () => {
            setLoading(true);
            try
            {
                const producerData = await dispatch(getProducer(id));

                const entityID = producerData?.entity_id;

                if (entityID)
                {
                    const entityData = await dispatch(getEntity(entityID));
                }
            } catch (error)
            {
                console.error(error);
            } finally
            {
                setLoading(false);
            }
        };

        fetchProducerAndEntity();
    }, [dispatch, id]);

    const tabs = [
        { title: 'Contact Information', disabled: false },
        // { title: 'Warehouse Receipts', disabled: false },
        { title: 'Banking Details', disabled: false },
        entity ? { title: 'Entity Details', disabled: false } : null,
    ].filter(tab => tab !== null);

    // Handle Logout
    const handleLogout = () => {
        localStorage.clear();
        navigate("/");
    };

    // URL Prefixes
    const brokerUrlPrefix = () => {
        if (view)
        {
            return 'broker/view';
        }
        else
        {
            return 'broker';
        }
    };

    return (
        <div>

            <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
                <NavLogo />
            </button>

            <aside
                id='logo-sidebar'
                className='fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0'
                aria-label='Sidebar'
            >
                <div className='h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800'>
                    <a href='/' className='flex items-center pl-2.5 mb-5'>
                        <Logo />
                    </a>

                    <ul className='space-y-2 font-medium'>
                        <li>
                            <a href="/home" className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg class="w-6 h-6  dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6.143 1H1.857A.857.857 0 0 0 1 1.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 7 6.143V1.857A.857.857 0 0 0 6.143 1Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 17 6.143V1.857A.857.857 0 0 0 16.143 1Zm-10 10H1.857a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 7 16.143v-4.286A.857.857 0 0 0 6.143 11Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286a.857.857 0 0 0 .857-.857v-4.286a.857.857 0 0 0-.857-.857Z" />
                                </svg>
                                <span className="ml-3">My Dashboards</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href={`/${brokerUrlPrefix()}/${entity_id}/${entity_mid}`}
                                className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6" />
                                </svg>
                                <span className='ml-3'>Broker Dashboard</span>
                            </a>
                        </li>
                        <li>
                            <a href={`/${brokerUrlPrefix()}/${entity_id}/${entity_mid}/customer_management`} className="flex items-center p-2 text-green-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                                </svg>
                                <span className="ml-3">Clients</span>
                            </a>
                        </li>
                        <li>
                            <a href={`/${brokerUrlPrefix()}/${entity_id}/${entity_mid}/bid_management`} className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                </svg>
                                <span className="ml-3">Bids</span>
                            </a>
                        </li>
                        <li>
                            <a href={`/${brokerUrlPrefix()}/${entity_id}/${entity_mid}/offer_management`} className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                </svg>
                                <span className="ml-3">Offers</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href={`/${brokerUrlPrefix()}/${entity_id}/${entity_mid}/live_market`} className='flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    stroke-width='1.5'
                                    stroke='currentColor'
                                    class='w-6 h-6'
                                >
                                    <path
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                        d='M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z'
                                    />
                                </svg>
                                <span className='ml-3'>ATS</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href={`/${brokerUrlPrefix()}/${entity_id}/${entity_mid}/provision_receipts`} className='flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    stroke-width='1.5'
                                    stroke='currentColor'
                                    class='w-6 h-6'
                                >
                                    <path
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                        d='M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z'
                                    />
                                </svg>
                                <span className='ml-3'>Provisional Receipts</span>
                            </a>
                        </li>
                        <li className="my-2 fixed bottom-12 w-11/12">
                            <a onClick={handleLogout} className="cursor-pointer flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg class="w-6 h-6 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h11m0 0-4-4m4 4-4 4m-5 3H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h3" />
                                </svg>
                                <span className="flex-1 ml-3 whitespace-nowrap">Sign Out</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </aside>

            <div className="p-4 sm:ml-64">
                <div className="p-4 rounded-lg dark:border-gray-700">
                    <ChildHeaderBanner title="Client Details" /* producer={contact_name}  */ />


                    <div className="relative">
                        <CustomTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} type={app_consts.CUSTOMER_MANAGEMENT} home={`home`} />
                        <br />
                        {activeTab === 'Contact Information' &&
                            <div>
                                <div class="relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
                                    <div class="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
                                        <div>
                                            {/* <h5 class="mr-3 font-semibold dark:text-white">{contact_name}</h5> */}
                                            <p class="text-gray-500 dark:text-gray-400">Contact Information</p>
                                        </div>
                                        <div class="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
                                            {producer && !view && (
                                                <button
                                                    type='button'
                                                    onClick={() => setModal({ customerModal: true })}
                                                    className='flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800'
                                                >
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        viewBox='0 0 20 20'
                                                        fill='currentColor'
                                                        className='w-5 h-5 mr-2'
                                                    >
                                                        <path d='M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z' />
                                                        <path d='M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z' />
                                                    </svg>
                                                    Update Client
                                                </button>
                                            )}
                                            {/* <div class="flex items-center w-full space-x-3 md:w-auto">
                                                <button id="actionsDropdownButton" data-dropdown-toggle="actionsDropdown" class="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                                    <svg class="-ml-1 mr-1.5 w-5 h-5" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                        <path clip-rule="evenodd" fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                                                    </svg>
                                                    Actions
                                                </button>
                                                <div id="actionsDropdown" class="z-10 hidden bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                                                    <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="actionsDropdownButton">
                                                        <li>
                                                            <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Mass Edit</a>
                                                        </li>
                                                    </ul>
                                                    <div class="py-1">
                                                        <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Delete all</a>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <dl className="grid grid-cols-2 gap-4 mb-4">
                                        <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Contact ID</dt><dd className="flex items-center text-gray-500 dark:text-gray-400">{producer?.producer_id}</dd></div>

                                        <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                            <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Client Type</dt>
                                            <dd className="flex items-center text-gray-500 dark:text-gray-400">

                                                {producer?.type}
                                            </dd>
                                        </div>
                                        <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                            <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Contact Name</dt>
                                            <dd className="flex items-center text-gray-500 dark:text-gray-400">

                                                {producer?.contact_name}
                                            </dd>
                                        </div>
                                        <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                            <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Contact Email</dt>
                                            <dd className="flex items-center text-gray-500 dark:text-gray-400">

                                                {producer?.contact_email}
                                            </dd>
                                        </div>
                                        <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Contact Phone Number</dt><dd className="flex items-center text-gray-500 dark:text-gray-400">{producer?.contact_phone}</dd></div>
                                        <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Country</dt><dd className="text-gray-500 dark:text-gray-400">{producer?.country}</dd></div>
                                        <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Province</dt><dd className="text-gray-500 dark:text-gray-400">{producer?.province}</dd></div>
                                        <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Commodities</dt><dd className="text-gray-500 dark:text-gray-400">{producer?.commodities && producer?.commodities.length > 0 ? producer?.commodities.join(', ')?.replace(/_/g, ' ') : 'No commodities'}</dd></div>
                                    </dl>
                                </div>
                            </div>
                        }

                        {activeTab === 'Banking Details' &&
                            <div>
                                <div class="relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
                                    <div class="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
                                        <div>
                                            {/* <h5 class="mr-3 font-semibold dark:text-white">{contact_name}</h5> */}
                                            <p class="text-gray-500 dark:text-gray-400">Contact Wallet Information</p>
                                        </div>
                                        <div class="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
                                            {producer?.bank_account[0] && !view ? (
                                                <button
                                                    type='button'
                                                    // onClick={() => setBankModal({ producerBankModal: true })}
                                                    className='flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800'
                                                >
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        viewBox='0 0 20 20'
                                                        fill='currentColor'
                                                        className='w-5 h-5 mr-2'
                                                    >
                                                        <path d='M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z' />
                                                        <path d='M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z' />
                                                    </svg>
                                                    Update Bank Details
                                                </button>
                                            ) : producer && !view &&
                                            (<button
                                                type='button'
                                                onClick={() => setBankModal({ producerBankModal: true })}
                                                className='flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800'
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    viewBox='0 0 20 20'
                                                    fill='currentColor'
                                                    className='w-5 h-5 mr-2'
                                                >
                                                    <path d='M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z' />
                                                    <path d='M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z' />
                                                </svg>
                                                Add Bank Account
                                            </button>
                                            )}
                                            {/* <div class="flex items-center w-full space-x-3 md:w-auto">
                                                <button id="actionsDropdownButton" data-dropdown-toggle="actionsDropdown" class="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                                    <svg class="-ml-1 mr-1.5 w-5 h-5" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                        <path clip-rule="evenodd" fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                                                    </svg>
                                                    Actions
                                                </button>
                                                <div id="actionsDropdown" class="z-10 hidden bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                                                    <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="actionsDropdownButton">
                                                        <li>
                                                            <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Mass Edit</a>
                                                        </li>
                                                    </ul>
                                                    <div class="py-1">
                                                        <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Delete all</a>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <dl className="grid grid-cols-2 gap-4 mb-4">
                                        <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Contact ID</dt><dd className="flex items-center text-gray-500 dark:text-gray-400">{producer?.producer_id}</dd></div>

                                        <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                            <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Bank Name</dt>
                                            <dd className="flex items-center text-gray-500 dark:text-gray-400">

                                                {/* {producer?.type} */}
                                            </dd>
                                        </div>
                                        <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                            <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Bank Code</dt>
                                            <dd className="flex items-center text-gray-500 dark:text-gray-400">

                                                {/* {producer?.contact_name} */}
                                            </dd>
                                        </div>
                                        <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                            <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Wallet Name</dt>
                                            <dd className="flex items-center text-gray-500 dark:text-gray-400">

                                                {/* {producer?.contact_email} */}
                                            </dd>
                                        </div>
                                        <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Wallet Number</dt><dd className="flex items-center text-gray-500 dark:text-gray-400"></dd></div>
                                        <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Wallet Status</dt><dd className="text-gray-500 dark:text-gray-400"></dd></div>
                                    </dl>
                                </div>
                                <div class="relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
                                    <div class="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
                                        <div>
                                            <p class="text-gray-500 dark:text-gray-400">Contact Bank Information</p>
                                        </div>

                                    </div>
                                    <dl className="grid grid-cols-2 gap-4 mb-4">
                                        <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                            <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Bank Account Name</dt>
                                            <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                {producer?.bank_account[0]?.bank_account_name}
                                            </dd>
                                        </div>
                                        <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                            <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Full Bank Name</dt><dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                {producer?.bank_account[0]?.bank_name.replace(/_/g, ' ').toUpperCase()}
                                            </dd>
                                        </div>
                                        <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Bank Account Number</dt><dd className="flex items-center text-gray-500 dark:text-gray-400">{producer?.bank_account[0]?.bank_account_number}</dd></div>
                                        <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Bank Branch Name & Address</dt><dd className="text-gray-500 dark:text-gray-400">{producer?.bank_account[0]?.bank_address}</dd></div>
                                        <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Bank SWIFT Code</dt><dd className="text-gray-500 dark:text-gray-400">{producer?.bank_account[0]?.swift_code}</dd></div>
                                        <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Bank Status</dt><dd className="text-gray-500 dark:text-gray-400">{producer?.bank_account[0]?.status.toUpperCase()}</dd></div>

                                    </dl>
                                </div>

                            </div>
                        }
                        {
                            entity ? (
                                <>
                                    {activeTab === 'Entity Details' && (
                                        <div>
                                            <dl className="sm:mb-5">
                                                <h5 className="text-xl font-bold dark:text-white">
                                                    {entity?.entity_name}
                                                </h5>
                                            </dl>

                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                                                <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Entity Type</dt><dd className="flex items-center text-gray-500 dark:text-gray-400">{entity?.entity_type}</dd></div>
                                                <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Entity Name</dt><dd className="flex items-center text-gray-500 dark:text-gray-400">{entity?.entity_name}</dd></div>
                                                <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Entity Registration Number</dt><dd className="flex items-center text-gray-500 dark:text-gray-400">{entity?.entity_reg_no}</dd></div>
                                                <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Entity Tax Number</dt><dd className="flex items-center text-gray-500 dark:text-gray-400">{entity?.entity_tax_no}</dd></div>

                                                {
                                                    producer.entity_reg_cert_url ? (
                                                        <DocumentViewer
                                                            name="Registration Certificate"
                                                            url={producer.entity_reg_cert_url}
                                                        // onEditClick={() => openUploadModal('Registration Certificate')}
                                                        />
                                                    ) : <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white"></dt><dd className="text-gray-500 dark:text-gray-400">No Entity Registration Certificate Found</dd></div>

                                                }

                                                {
                                                    producer.entity_reg_cert_url ? (
                                                        <DocumentViewer
                                                            name="Tax Certificate"
                                                            url={producer.entity_tax_cert_url}
                                                        // onEditClick={() => openUploadModal('Tax Certificate')}
                                                        />
                                                    ) : <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white"></dt><dd className="text-gray-500 dark:text-gray-400">No Entity Tax Certificate Found</dd></div>

                                                }

                                                {
                                                    producer.entity_reg_cert_url ? (
                                                        <DocumentViewer
                                                            name="Board Resolution"
                                                            url={producer.board_resolution_url}
                                                        // onEditClick={() => openUploadModal('Board Resolution')}
                                                        />
                                                    ) : <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white"></dt><dd className="text-gray-500 dark:text-gray-400">No Entity Board Resolution Found</dd></div>

                                                }

                                                {
                                                    producer.entity_reg_cert_url ? (
                                                        <DocumentViewer
                                                            name="Profile Document"
                                                            url={producer.entity_profile_doc_url}
                                                        // onEditClick={() => openUploadModal('Profile Document')}
                                                        />
                                                    ) : <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white"></dt><dd className="text-gray-500 dark:text-gray-400">No Entity Profile Document Found</dd></div>

                                                }
                                            </div>

                                        </div>
                                    )}
                                </>
                            ) : null
                        }
                    </div>
                    {producer && (
                        <AddProducerBankDetailsModal
                            showModal={bankModal.producerBankModal}
                            closeModal={closeBankModal}
                            openSelectedModal={openSelectedBankModal}
                            producerData={producer}
                        />
                    )}
                    {producer && (
                        <UpdateBrokerClientModal
                            showModal={modal.customerModal}
                            closeModal={closeCustomerModal}
                            openSelectedModal={openSelectedModal}
                            producerData={producer}
                        />
                    )}
                    {loading && (
                        <div className="text-center">
                            <div role="status">
                                <svg
                                    aria-hidden="true"
                                    className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                    />
                                </svg>
                                <span className="sr-only">Please wait...</span>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div >
    );
};

export default CustomerDetails;
