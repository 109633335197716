import React, { useEffect, useRef, useState } from 'react';
import LatoRegular from '../../utils/fonts/Lato-Regular.ttf';
import LatoBold from '../../utils/fonts/Lato-Bold.ttf';
import generatePDF from 'react-to-pdf';
import DynamicNavLogo from '../../components/DynamicNavLogo';
import { format } from 'date-fns';
import * as app_consts from '../../utils/constants';
import { convertUnderscoreToTitleCase } from '../../utils/functionalUtils';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import PDFSVGLogo from '../../components/PDFSVGLogo';

const GoodsReceivedNoteStatementsPDF = ({ data, downloadGoodsReceivedStatement, downloadable, setDownloadGoodsReceivedStatement }) => {

    const targetRef = useRef();
    const [goodsReceivedData, setGoodsReceivedData] = useState(data);
    const [total, setTotal] = useState({ truck_loads: "", no_of_bags: "", quantity: "" });

    // Register the Lato font
    Font.register({ family: 'Lato', fonts: [{ src: LatoRegular }, { src: LatoBold, fontWeight: 'bold' }] });

    useEffect(() => {
        if (data)
        {
            setGoodsReceivedData(data);
        }
    }, [data]);

    useEffect(() => {
        if (downloadable === true && downloadGoodsReceivedStatement === true)
        {
            generatePDF(targetRef, { filename: `${goodsReceivedData?.producer_unique_id} Goods Received Statement` || " Goods Received Statement" });
            setDownloadGoodsReceivedStatement(false);
        }
    }, [downloadGoodsReceivedStatement]);


    // Goods Received UseEffect
    useEffect(() => {
        if (goodsReceivedData?.goods_received)
        {
            setTotal(calculateTotals());
        }
    }, [goodsReceivedData]);

    // Calculate Total Quantity
    const calculateTotals = () => {
        const truck_load_total = goodsReceivedData?.goods_received?.reduce((acc, obj) => acc + parseFloat(obj.truck_load_no || 0), 0);
        const quantity_total = goodsReceivedData?.goods_received?.reduce((acc, obj) => acc + parseFloat(obj.quantity || 0), 0);
        const no_of_bags_total = goodsReceivedData?.goods_received?.reduce((acc, obj) => acc + parseFloat(obj.no_of_bags || 0), 0);

        return { truck_loads: truck_load_total, no_of_bags: no_of_bags_total, quantity: parseFloat(quantity_total?.toFixed(2)) };
    };

    const getSortedDeliveryNotes = (deliveryNotes) => {
        if (!deliveryNotes) return [];

        return deliveryNotes.sort((a, b) => {
            const dateA = new Date(a.date_received);
            const dateB = new Date(b.date_received);
            return dateB - dateA; // Descending order
        });
    };

    return (
        <>
            {
                goodsReceivedData && (
                    <Document title="Goods Received Notes Statement">
                        <Page size="A4" style={{
                            flexDirection: "column",
                            backgroundColor: "rgba(0,0,0,0)",
                            height: "100%",
                            fontFamily: "Lato"
                        }}>
                            <View fixed>
                                <View style={{
                                    width: "100%",
                                    paddingRight: 10,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: 5,
                                }}>
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        ViewTransform: "uppercase",
                                        width: "50%",
                                        paddingLeft: 20,
                                    }}>
                                        <View style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start", position: 'relative' }}>
                                            <PDFSVGLogo height='100px' width='100%' />
                                        </View>
                                    </View>

                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "end",
                                        justifyContent: "end",
                                        textTransform: "uppercase",
                                        width: "50%",
                                        marginRight: 10,
                                    }}>
                                        <View style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end",
                                            marginBottom: 5,
                                            marginTop: 5
                                        }}>
                                            <Text style={{ letterSpacing: 2, fontWeight: 400, fontSize: 14 }}>Warehouse Operator:</Text>
                                            <Text style={{ fontWeight: 600, fontSize: 14, color: "#252629", letterSpacing: 1, lineHeight: 1.2 }}>{goodsReceivedData.entity_name && goodsReceivedData.entity_name}</Text>
                                        </View>
                                        <View style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end",
                                        }}>
                                            <Text style={{ letterSpacing: 2, fontWeight: 400, fontSize: 14 }}>Date Generated:</Text>
                                            <Text style={{ fontWeight: 600, letterSpacing: 1, fontSize: 14, color: "#252629", lineHeight: 1.2 }}>{format(new Date(), 'yyyy-MM-dd  HH:mm:ss')}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            <View fixed>
                                <View style={{
                                    width: "100%",
                                    paddingRight: 10,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: 25,
                                }}>
                                    <Text style={{ fontWeight: 600, paddingLeft: 6, fontSize: 25, color: "#000", wordSpacing: "0.05px", lineHeight: 0.2, }}>
                                        Goods Received Notes Statement
                                    </Text>
                                </View>
                            </View>
                            <View style={{
                                width: "100%",
                                paddingRight: 5,
                                paddingTop: 5,
                                paddingBottom: 5,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "start",
                            }}>
                                <View style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    ViewTransform: "uppercase",
                                    width: "50%",
                                    paddingLeft: 20,
                                    marginTop: 15
                                }}>
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        paddingLeft: 5
                                    }}>
                                        <Text style={{ fontWeight: 600, fontSize: 14, color: "#000" }}>DEPOSITOR</Text>
                                        <Text style={{ fontWeight: 400, fontSize: 14 }}>{goodsReceivedData.producer_type ? <>{goodsReceivedData.owner_name !== app_consts.INDIVIDUAL.toLowerCase() ? goodsReceivedData?.producer_entity_name.toUpperCase() : goodsReceivedData?.producer_contact_name.toUpperCase()}</> : ""}</Text>
                                    </View>
                                </View>
                                <View style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    ViewTransform: "uppercase",
                                    width: "50%",
                                    paddingLeft: 20,
                                    marginTop: 15
                                }}>
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginTop: 5,
                                        paddingLeft: 6
                                    }}>
                                        <Text style={{ fontWeight: 600, fontSize: 14, color: "#000" }}>DEPOSITOR ID</Text>
                                        <Text style={{ fontWeight: 400, fontSize: 14 }}>{goodsReceivedData.producer_unique_id && (goodsReceivedData.producer_unique_id.toUpperCase())}</Text>
                                    </View>
                                </View>
                            </View>

                            <View style={{ paddingRight: "30px", paddingLeft: 30, marginTop: 20 }}>
                                <View style={{
                                    color: "rgba(0, 0, 0, 0.87)",
                                    marginTop: 5,
                                    borderRadius: 4,
                                    borderStyle: "solid",
                                    borderColor: "#e5e9f2",
                                }}>
                                    {
                                        goodsReceivedData?.warehouse_receipts?.map((goods) => {
                                            return (
                                                <>
                                                    <View style={{
                                                        marginTop: 5,
                                                        marginBottom: 5,
                                                        width: "100%",
                                                    }}>
                                                        <View style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            borderTopWidth: 2,
                                                            borderStyle: "solid",
                                                            borderColor: "#D1D1D1",
                                                        }}>
                                                            <View style={{
                                                                width: "40%",
                                                                padding: 3,
                                                            }}>
                                                                <Text style={{
                                                                    marginTop: 5,
                                                                    color: "#302f2f",
                                                                    fontSize: 12,
                                                                    lineHeight: 1.6,
                                                                    fontWeight: 700,
                                                                    lineHeight: "normal",
                                                                    textAlign: "start",
                                                                    padding: 3
                                                                }}>Receipt No.</Text>
                                                            </View>
                                                            <View style={{
                                                                width: "20%",
                                                                padding: 3,
                                                            }}>
                                                                <Text style={{
                                                                    marginTop: 5,
                                                                    color: "#302f2f",
                                                                    fontSize: 12,
                                                                    lineHeight: 1.6,
                                                                    textAlign: "end",
                                                                    fontWeight: 700,
                                                                    lineHeight: "normal",
                                                                    padding: 3
                                                                }}>Commodity</Text>
                                                            </View>
                                                            <View style={{
                                                                width: "20%",
                                                                padding: 3,
                                                            }}>
                                                                <Text style={{
                                                                    marginTop: 5,
                                                                    color: "#302f2f",
                                                                    fontSize: 12,
                                                                    lineHeight: 1.6,
                                                                    fontWeight: 700,
                                                                    lineHeight: "normal",
                                                                    textAlign: "end",
                                                                    padding: 3
                                                                }}>Quantity (MT)</Text>
                                                            </View>
                                                            <View style={{
                                                                width: "20%",
                                                                padding: 3,
                                                            }}>
                                                                <Text style={{
                                                                    marginTop: 5,
                                                                    color: "#302f2f",
                                                                    fontSize: 12,
                                                                    lineHeight: 1.6,
                                                                    fontWeight: 700,
                                                                    lineHeight: "normal",
                                                                    textAlign: "end",
                                                                    padding: 3
                                                                }}>Date Created</Text>
                                                            </View>
                                                        </View>
                                                        <View style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            borderColor: "#e5e9f2",
                                                            borderStyle: "solid",
                                                            borderBottomWidth: 1,

                                                        }}>
                                                            <View style={{
                                                                width: "40%",
                                                                padding: 3,
                                                            }}>
                                                                <Text style={{
                                                                    color: "#252b32",
                                                                    fontSize: 8,
                                                                    fontWeight: 400,
                                                                    textAlign: "start",
                                                                    padding: 3
                                                                }}>{goods?.receipt_number ? goods?.receipt_number : "---"}</Text>
                                                            </View>
                                                            <View style={{
                                                                width: "20%",
                                                                padding: 3,
                                                            }}>
                                                                <Text style={{
                                                                    color: "#252b32",
                                                                    fontSize: 10,
                                                                    fontWeight: 400,
                                                                    textAlign: "end",
                                                                    padding: 3
                                                                }}>{goods.commodity ? convertUnderscoreToTitleCase(goods.commodity.toUpperCase()) : "---"}</Text>
                                                            </View>
                                                            <View style={{
                                                                width: "20%",
                                                                padding: 3,
                                                            }}>
                                                                <Text style={{
                                                                    color: "#252b32",
                                                                    fontSize: 10,
                                                                    fontWeight: 400,
                                                                    textAlign: "end",
                                                                    padding: 3
                                                                }}>{goods?.quantity ? parseFloat(goods.quantity).toFixed(2) : "---"}</Text>
                                                            </View>
                                                            <View style={{
                                                                width: "20%",
                                                                padding: 3,
                                                            }}>
                                                                <Text style={{
                                                                    color: "#252b32",
                                                                    fontSize: 9,
                                                                    fontWeight: 400,
                                                                    textAlign: "end",
                                                                    padding: 3
                                                                }}>{goods?.date_created ? (format(new Date(goods.date_created), 'yyyy-MM-dd  HH:mm:ss')) : "---"}</Text>
                                                            </View>
                                                        </View>
                                                        {/* Map the GRN Statements */}
                                                        {
                                                            goods?.delivery_notes && goods?.delivery_notes?.length > 0 ? (
                                                                <>
                                                                    <View style={{
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        backgroundColor: "#DFDFDF",
                                                                    }}>
                                                                        <View style={{
                                                                            width: "20%",
                                                                            padding: 3,
                                                                        }}>
                                                                            <Text style={{
                                                                                margin: "auto",
                                                                                marginTop: 5,
                                                                                color: "#302f2f",
                                                                                fontSize: 12,
                                                                                lineHeight: 1.6,
                                                                                fontWeight: 700,
                                                                                lineHeight: "normal",
                                                                                textAlign: "start",
                                                                                padding: 3
                                                                            }}>GRN No.</Text>
                                                                        </View>
                                                                        <View style={{
                                                                            width: "20%",
                                                                            padding: 3,
                                                                        }}>
                                                                            <Text style={{
                                                                                margin: "auto",
                                                                                marginTop: 5,
                                                                                color: "#302f2f",
                                                                                fontSize: 12,
                                                                                lineHeight: 1.6,
                                                                                fontWeight: 700,
                                                                                lineHeight: "normal",
                                                                                textAlign: "start",
                                                                                padding: 3
                                                                            }}>Truck Load No.</Text>
                                                                        </View>
                                                                        <View style={{
                                                                            width: "20%",
                                                                            padding: 3,
                                                                        }}>
                                                                            <Text style={{
                                                                                margin: "auto",
                                                                                marginTop: 5,
                                                                                color: "#302f2f",
                                                                                fontSize: 12,
                                                                                lineHeight: 1.6,
                                                                                textAlign: "start",
                                                                                fontWeight: 700,
                                                                                lineHeight: "normal",
                                                                                padding: 3
                                                                            }}>No. of Bags</Text>
                                                                        </View>
                                                                        <View style={{
                                                                            width: "20%",
                                                                            padding: 3,
                                                                        }}>
                                                                            <Text style={{
                                                                                margin: "auto",
                                                                                marginTop: 5,
                                                                                color: "#302f2f",
                                                                                fontSize: 12,
                                                                                lineHeight: 1.6,
                                                                                fontWeight: 700,
                                                                                lineHeight: "normal",
                                                                                textAlign: "start",
                                                                                padding: 3
                                                                            }}>Quantity (MT)</Text>
                                                                        </View>
                                                                        <View style={{
                                                                            width: "20%",
                                                                            padding: 3,
                                                                        }}>
                                                                            <Text style={{
                                                                                margin: "auto",
                                                                                marginTop: 5,
                                                                                color: "#302f2f",
                                                                                fontSize: 12,
                                                                                lineHeight: 1.6,
                                                                                fontWeight: 700,
                                                                                lineHeight: "normal",
                                                                                textAlign: "start",
                                                                                padding: 3
                                                                            }}>Date Received</Text>
                                                                        </View>
                                                                    </View>
                                                                </>
                                                            )
                                                                :
                                                                <>
                                                                    <View style={{
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        backgroundColor: "#D1D1D1",
                                                                    }}>
                                                                        <View style={{
                                                                            width: "100%",
                                                                            padding: 1,
                                                                            marginTop: 5,
                                                                            marginBottom: 5,
                                                                        }}>
                                                                            <Text style={{
                                                                                color: "#302f2f",
                                                                                fontSize: 8,
                                                                                lineHeight: 1.6,
                                                                                fontWeight: 600,
                                                                                lineHeight: "normal",
                                                                                textAlign: "center",
                                                                                padding: 3
                                                                            }}>No Goods Received Notes Found</Text>
                                                                        </View>
                                                                    </View>
                                                                </>
                                                        }
                                                        {
                                                            goods?.delivery_notes && getSortedDeliveryNotes(goods.delivery_notes).map((delivery, index) => {
                                                                return (
                                                                    <>
                                                                        <View style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            borderColor: "#e5e9f2",
                                                                            borderStyle: "solid",
                                                                            borderBottomWidth: 1,
                                                                        }}>
                                                                            <View style={{
                                                                                width: "20%",
                                                                                padding: 1,
                                                                            }}>
                                                                                <Text style={{
                                                                                    marginTop: 5,
                                                                                    color: "#252b32",
                                                                                    fontSize: 10,
                                                                                    fontWeight: 400,
                                                                                    textAlign: "center",
                                                                                    padding: 1
                                                                                }}>{delivery?.grn_no ? delivery?.grn_no : "---"}</Text>
                                                                            </View>
                                                                            <View style={{
                                                                                width: "20%",
                                                                                padding: 1,
                                                                            }}>
                                                                                <Text style={{
                                                                                    marginTop: 5,
                                                                                    color: "#252b32",
                                                                                    fontSize: 10,
                                                                                    fontWeight: 400,
                                                                                    textAlign: "center",
                                                                                    padding: 1
                                                                                }}>{delivery?.truck_load_no ? delivery?.truck_load_no : "---"}</Text>
                                                                            </View>
                                                                            <View style={{
                                                                                width: "20%",
                                                                                padding: 3,
                                                                            }}>
                                                                                <Text style={{
                                                                                    marginTop: 5,
                                                                                    color: "#252b32",
                                                                                    fontSize: 10,
                                                                                    fontWeight: 400,
                                                                                    textAlign: "center",
                                                                                    padding: 3
                                                                                }}>{delivery?.no_of_bags ? delivery?.no_of_bags : "---"}</Text>
                                                                            </View>
                                                                            <View style={{
                                                                                width: "20%",
                                                                                padding: 1,
                                                                            }}>
                                                                                <Text style={{
                                                                                    marginTop: 5,
                                                                                    color: "#252b32",
                                                                                    fontSize: 10,
                                                                                    fontWeight: 400,
                                                                                    textAlign: "center",
                                                                                    padding: 1
                                                                                }}>{delivery?.quantity ? parseFloat(delivery?.quantity).toFixed(2) : "---"}</Text>
                                                                            </View>
                                                                            <View style={{
                                                                                width: "20%",
                                                                                padding: 1,
                                                                            }}>
                                                                                <Text style={{
                                                                                    marginTop: 5,
                                                                                    color: "#252b32",
                                                                                    fontSize: 10,
                                                                                    fontWeight: 400,
                                                                                    textAlign: "center",
                                                                                    padding: 1
                                                                                }}>{delivery?.date_received ? (format(new Date(delivery.date_received), 'yyyy-MM-dd  HH:mm:ss')) : "---"}</Text>
                                                                            </View>
                                                                        </View>
                                                                    </>

                                                                );
                                                            })
                                                        }

                                                    </View>
                                                </>
                                            );
                                        })
                                    }
                                </View>
                            </View>
                        </Page>
                    </Document>
                )
            }
        </>
    );
};

export default GoodsReceivedNoteStatementsPDF;