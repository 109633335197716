import React, { useEffect, useState } from 'react';
import Logo from '../../components/Logo';
import NavLogo from '../../components/NavLogo';
import { getEntities, getEntityProfileList, registerNewMember, retrieveEntityListData, retrieveEntityListReset } from '../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import countries from '../../utils/countries.json';
import provinces from '../../utils/provinces.json';
import HeaderBanner from '../../layouts/HeaderBanner';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as app_consts from '../../utils/constants';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CustomTabs from '../../layouts/CustomTabs';
import TableComp from '../../components/table/TableComp';

const MembershipManagementDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const entities = useSelector((state) => state.entityList.data);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [step, setStep] = useState(1);
  const [activeTab, setActiveTab] = useState('All');
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [entity_data, setEntityData] = useState();
  const [entity_list, setEntityList] = useState([]);


  const { entity_id, entity_mid } = useParams();

  const pageSize = 20;

  const tabs = [
    { title: 'All', disabled: !isDataFetched },
    { title: 'Active', disabled: !isDataFetched },
    { title: 'Pending Verification', disabled: !isDataFetched },
    { title: 'Pending Activation', disabled: !isDataFetched }
  ];

  // Code that Runs When the Page First Opens 
  useEffect(() => {
    dispatch(retrieveEntityListData());
  }, []);

  // Listen to Entity List Changes
  const entity_list_data_listener = useSelector((state) => state.retrieveEntityListData);

  useEffect(() => {
    if (entity_list_data_listener.status !== app_consts.IDLE)
    {
      if (entity_list_data_listener.isLoading)
      {
        setLoading(true);
      }
      else
      {
        if (entity_list_data_listener.error)
        {
          setError(entity_list_data_listener.error);
        }
        else if (entity_list_data_listener.data)
        {
          setIsDataFetched(true);
          setEntityData(entity_list_data_listener.data);
          setEntityList(entity_list_data_listener.data);
        }
        dispatch(retrieveEntityListReset());
        setLoading(false);
      }
    }
  }, [entity_list_data_listener]);

  // UseEffect for Entities
  useEffect(() => {
  }, [entities]);

  // UseEffect for Active Tab
  useEffect(() => {
    filterEntities();
  }, [activeTab]);

  // Filter Entities
  const filterEntities = () => {
    let filteredData = [];

    if (activeTab === 'All')
    {
      filteredData = entity_data;
    } else if (activeTab === 'Active')
    {
      filteredData = entity_data?.filter(item => item?.verified === 1 && item?.active === 1);
    } else if (activeTab === 'Pending Verification')
    {
      filteredData = entity_data?.filter(item => item?.verified === 0 && item?.active === 0);
    } else if (activeTab === 'Pending Activation')
    {
      filteredData = entity_data?.filter(item => item?.active === 0 && item?.verified === 1);
    }
    else
    {
      filteredData = entity_data;
    }
    setSearchTerm("");
    setEntityList(filteredData);
  };



  const RegisterSchema = yup.object().shape({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    mobile: yup.string().required('Mobile number is required'),
    email: yup
      .string()
      .email('Email is invalid')
      .required('Email address is required'),
    orgName: yup.string().required('Organization name is required.'),
    pacraRegNo: yup.string().required('PACRA Registration No. is required.'),
    zraTPIN: yup.string().required('ZRA TPIN Number is required.'),
    profileType: yup
      .string()
      .required('Please select a membership type before proceeding'),
    entity_type: yup
      .string()
      .required('Please select an organization category'),
    entity_email: yup
      .string()
      .email('Please enter a valid email address')
      .required('Please enter the organization email address'),
    entity_phone: yup
      .string()
      .required('Please enter the organization phone number'),
    postal_address: yup.string().required('Please enter the postal address'),
    physical_address: yup
      .string()
      .required('Please enter the organization street address'),
    country: yup.string().required('Please select a country'),
    province: yup.string().required('Please select a province'),
  });

  const handlePhoneChange = (phone) => {
    const phoneNumber = parsePhoneNumberFromString(phone);
    const formattedPhoneNumber =
      phoneNumber && phoneNumber.isValid()
        ? phoneNumber.formatInternational()
        : phone;
    formik.handleChange({
      target: {
        name: 'entity_phone',
        value: formattedPhoneNumber,
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      mobile: '',
      email: '',
      verified: 0,
      active: 0,
      orgName: '',
      pacraRegNo: '',
      zraTPIN: '',
      profileType: '',
      entity_type: '',
      entity_email: '',
      entity_phone: '',
      postal_address: '',
      physical_address: '',
      province: '',
      country: '',
    },
    validationSchema: RegisterSchema,

    onSubmit: async (values) => {
      const password = Math.random().toString(36).slice(2, 10);

      const rolesMap = {
        broker: ['administrator', 'trader', 'clearing', 'settlement'],
        warehouse_operator: [
          'administrator',
          'manager',
          'inventory_control',
          'quality_assurance',
          'operator',
          'logistics',
          'billing',
          'customer_service',
        ],
        cooperative: [
          'administrator',
          'manager',
          'inventory_control',
          'quality_assurance',
          'operator',
          'logistics',
          'billing',
          'customer_service',
        ],
        service_provider: [
          'administrator',
          'manager',
          'inventory_control',
          'quality_assurance',
          'operator',
          'logistics',
          'billing',
          'customer_service',
        ],
        investor: [
          'administrator',
          'manager',
          'inventory_control',
          'quality_assurance',
          'operator',
          'logistics',
          'billing',
          'customer_service',
        ],
        financial_institution: [
          'administrator',
          'analyst',
          'risk_management',
          'investment_advisory',
          'compliance',
        ],
        //       government: ["administrator", "regulatory_affairs", "enforcement", "policy_analysis"],
        //       researcher: ["administrator", "data_analysis", "reporting", "market_analysis"]
      };

      const roles = rolesMap[values.profileType];

      setLoading(true);

      try
      {
        const memberData = {
          user: {
            first_name: values.first_name,
            last_name: values.last_name,
            mobile: values.mobile,
            email: values.email,
            password_hash: password,
            verified: 0,
            active: 0,
          },
          profile: {
            name: values.orgName,
            roles,
            status: 'pending',
            type: values.profileType,
          },
          entity: {
            entity_name: values.orgName,
            entity_reg_no: values.pacraRegNo,
            entity_tax_no: values.zraTPIN,
            entity_type: values.entity_type,
            entity_category: values.profileType,
            entity_email: values.entity_email,
            entity_phone: values.entity_phone,
            postal_address: values.postal_address,
            physical_address: values.physical_address,
            province: values.province,
            country: values.country,
            status: 'pending',
            verified: 0,
            active: 0,
          },
        };

        await dispatch(registerNewMember(memberData));
        setLoading(false);
        // navigate('/exchange/entity_details/${entity.id}/${entity.entity_name}');
        window.location.reload();
      } catch (error)
      {
        setLoading(false);
        setError(error.response.data.message);
      } finally
      {
        setLoading(false);
      }
    },
  });

  // Registration Modal state and functions
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Registration Modal Next and Previous buttons
  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  // Calculate the start and end index for the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;


  const filteredEntities = () => {
    let displayedEntities;
    displayedEntities = entity_data?.filter((entity) => {
      const combinedParams = [
        entity.entity_name,
        entity.organization_id,
        entity.entity_category,
        entity.entity_email,
        entity.entity_phone,
        entity.status,
      ]
        .join(' ')
        .toLowerCase();

      return combinedParams.includes(searchTerm?.toLowerCase());
    }).slice(startIndex, endIndex);


    setEntityList(displayedEntities || []);
  };

  // UseEffect for Search 
  useEffect(() => {
    if (searchTerm) 
    {
      filteredEntities(searchTerm);
    }
    else
    {
      filterEntities();
    }
  }, [searchTerm]);

  // Handle Logout
  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  return (
    <div>
      <button
        data-drawer-target='logo-sidebar'
        data-drawer-toggle='logo-sidebar'
        aria-controls='logo-sidebar'
        type='button'
        className='inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600'
      >
        <span className='sr-only'>Open sidebar</span>
        <svg
          className='w-6 h-6'
          aria-hidden='true'
          fill='currentColor'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            clip-rule='evenodd'
            fill-rule='evenodd'
            d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
          ></path>
        </svg>
        <NavLogo />
      </button>

      <aside
        id='logo-sidebar'
        className='fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0'
        aria-label='Sidebar'
      >
        <div className='h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800'>
          <a href='/' className='flex items-center pl-2.5 mb-5'>
            <Logo />
          </a>

          <ul className='space-y-2 font-medium'>
            <li>
              <a href="/home" className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg class="w-6 h-6 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6.143 1H1.857A.857.857 0 0 0 1 1.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 7 6.143V1.857A.857.857 0 0 0 6.143 1Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 17 6.143V1.857A.857.857 0 0 0 16.143 1Zm-10 10H1.857a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 7 16.143v-4.286A.857.857 0 0 0 6.143 11Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286a.857.857 0 0 0 .857-.857v-4.286a.857.857 0 0 0-.857-.857Z" />
                </svg>
                <span className="ml-3">My Dashboards</span>
              </a>
            </li>
            <li>
              <a href={`/exchange/${entity_id}/${entity_mid}`} className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 dark:text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                </svg>

                <span className="ml-3">Exchange Dashboard</span>
              </a>
            </li>
            <li>
              <a
                href={`/exchange/${entity_id}/${entity_mid}/membership`}
                className='flex items-center p-2 text-green-600 rounded-lg dark:text-white hover:text-green-600 dark:hover:bg-gray-700 group'
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class='w-6 h-6'>
                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
                </svg>
                <span className='ml-3'>Membership Management</span>
              </a>
            </li>
            <li>
              <a href={`/exchange/${entity_id}/${entity_mid}/warehouse_management`} className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                </svg>


                <span className="ml-3">Warehouse Operator Management</span>
              </a>
            </li>
            <li>
              <a href={`/exchange/${entity_id}/${entity_mid}/broker_management`}
                className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                </svg>
                <span className="ml-3">Broker Management</span>
              </a>
            </li>
            <li>
              <a href={`/exchange/${entity_id}/${entity_mid}/cooperative_management`}
                className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-6 h-6">
                  <circle cx="5.5" cy="12.5" r="2.5" />
                  <circle cx="12" cy="5.5" r="2.5" />
                  <circle cx="18.5" cy="12.5" r="2.5" />
                  <path d="M7 12.5a5 5 0 005 5m5-5a5 5 0 00-5-5m0 0a5 5 0 00-5 5m5-5v10" />
                </svg>
                <span className="ml-3">Cooperative Management</span>
              </a>
            </li>
            <li>
              <a href={`/exchange/${entity_id}/${entity_mid}/user_management`}
                className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                </svg>

                <span className="ml-3">User Management</span>
              </a>
            </li>
            <li>
              <a href={`/exchange/${entity_id}/${entity_mid}/producer_management`}
                className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                </svg>

                <span className="ml-3">Producer Management</span>
              </a>
            </li>

            <li className='my-2 fixed bottom-12 w-11/12'>
              <a
                onClick={handleLogout}
                className='cursor-pointer flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group'
              >
                <svg
                  class='w-6 h-6 dark:text-white'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 16 16'
                >
                  <path
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M4 8h11m0 0-4-4m4 4-4 4m-5 3H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h3'
                  />
                </svg>
                <span className='flex-1 ml-3 whitespace-nowrap'>Sign Out</span>
              </a>
            </li>
          </ul>
        </div>
      </aside>

      <div className='p-4 sm:ml-64'>
        <div className='p-4 rounded-lg dark:border-gray-700'>
          <HeaderBanner title='Membership Management' type={app_consts.MEMBERSHIP_MANAGEMENT} home={`home`} navInfo={{ entity_id, entity_mid }} />
          <div class='w-full relative bg-white dark:bg-gray-800 sm:rounded-lg'>
            <CustomTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            <div class='flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4'>
              <div class='w-full md:w-1/2'>
                <form class='flex items-center'>
                  <label for='simple-search' class='sr-only'>
                    Search
                  </label>
                  <div class='relative w-full'>
                    <div class='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <svg
                        aria-hidden='true'
                        class='w-5 h-5 text-gray-500 dark:text-gray-400'
                        fill='currentColor'
                        viewbox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                          clip-rule='evenodd'
                        />
                      </svg>
                    </div>
                    <input
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      type='text'
                      id='simple-search'
                      class='block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                      placeholder='Search'
                      required=''
                    />
                  </div>
                </form>
              </div>
              <div class='flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3'>
                <button
                  type='button'
                  onClick={openModal}
                  className='flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    className='w-5 h-5 mr-2'
                  >
                    <path d='M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z' />
                    <path d='M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z' />
                  </svg>
                  Register New Member
                </button>
              </div>
            </div>
          </div>
          <div className='relative'>
            <br />
            {
              loading ?
                <>
                  <div className="text-center mt-4 h-10">
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Please wait...</span>
                    </div>
                  </div>
                </>
                :
                <>
                  {
                    error ?
                      <>
                        <tr className='flex items-center w-full justify-center'>
                          <td colSpan="8" className="w-full text-center py-4">
                            <div role="status" className='text-red-600 flex flex-col items-center justify-center mb-4 leading-4'>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                            </div>
                            <span colSpan="8" className="text-gray-700 py-4 my-6 tracking-wider">
                              {error}
                            </span>
                          </td>
                        </tr>
                      </>
                      :
                      <>
                        <TableComp tableData={entity_list} tableType={app_consts.MEMBERSHIP_MANAGEMENT} tableHeading={"Entities"} />
                      </>
                  }
                </>
            }
          </div>
        </div>
      </div>
      {/* Registration Modal */}
      {isModalOpen && (
        <div className='fixed inset-0 flex items-center justify-center z-50 overflow-y-auto'>
          <div className='fixed inset-0 bg-black opacity-50'></div>
          <div className='relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 md:p-8 max-h-screen overflow-y-auto'>
            <div class='flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600'>
              <h3 class='text-lg font-semibold text-gray-900 dark:text-white'>
                Register New Member
              </h3>
              <button
                type='button'
                onClick={closeModal}
                class='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
                data-modal-toggle='updateProductModal'
              >
                <svg
                  aria-hidden='true'
                  class='w-5 h-5'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                    clip-rule='evenodd'
                  ></path>
                </svg>
                <span class='sr-only'>Close modal</span>
              </button>
            </div>
            {step === 1 && (
              <div>
                <form
                  className='space-y-4 md:space-y-6'
                  onSubmit={formik.handleSubmit}
                >
                  <ol className='items-center w-full sm:w-auto space-y-4 sm:flex sm:space-x-8 sm:space-y-0'>
                    <li className='flex items-center text-green-600 dark:text-green-500 space-x-2.5'>
                      <span className='flex items-center justify-center w-8 h-8 border border-green-600 rounded-full shrink-0 dark:border-green-500'>
                        1
                      </span>
                      <span>
                        <h4 className='font-medium leading-tight'>
                          Primary User Account
                        </h4>
                        <p className='text-sm'>Create Primary User Account</p>
                      </span>
                    </li>
                  </ol>
                  {/* ... (Step 1 form fields) ... */}
                  <div class='container grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4'>
                    <div>
                      <label
                        htmlFor='first_name'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        First Name
                      </label>
                      <input
                        type='text'
                        name='first_name'
                        id='first_name'
                        value={formik.values.first_name}
                        onChange={(e) =>
                          formik.handleChange(e.target.name)(
                            e.target.value.toUpperCase()
                          )
                        }
                        onBlur={formik.handleBlur}
                        className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                        required
                      />
                      {formik.touched.first_name && formik.errors.first_name ? (
                        <div className='p-2 mb-4 text-sm text-red-800 dark:text-red-400'>
                          {formik.errors.first_name}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <label
                        htmlFor='last_name'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        Last Name
                      </label>
                      <input
                        type='text'
                        name='last_name'
                        id='last_name'
                        value={formik.values.last_name}
                        onChange={(e) =>
                          formik.handleChange(e.target.name)(
                            e.target.value.toUpperCase()
                          )
                        }
                        onBlur={formik.handleBlur}
                        className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                        required
                      />
                      {formik.touched.last_name && formik.errors.last_name ? (
                        <div className='p-2 mb-4 text-sm text-red-800 dark:text-red-400'>
                          {formik.errors.last_name}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <label
                        htmlFor='mobile'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        Mobile Number
                      </label>
                      <PhoneInput
                        country={'zm'}
                        value={formik.values.mobile}
                        onChange={(mobile) =>
                          formik.setFieldValue('mobile', mobile)
                        }
                        inputClass='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                        containerClass='w-full'
                        buttonClass='dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                        required
                      />
                    </div>
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <div className='p-2 mb-4 text-sm text-red-800 dark:text-red-400'>
                        {formik.errors.mobile}
                      </div>
                    ) : null}
                    <div>
                      <label
                        htmlFor='email'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        Email (corporate email)
                      </label>
                      <input
                        type='email'
                        name='email'
                        id='email'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                        placeholder='Enter email address'
                        required
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className='p-2 mb-4 text-sm text-red-800 dark:text-red-400'>
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {error && (
                    <div
                      className='p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400'
                      role='alert'
                    >
                      <span className='font-medium'>Error: </span>
                      {error}
                    </div>
                  )}
                  {/* Close button */}
                  <div className='flex space-x-4'>
                    <button
                      type='button'
                      onClick={closeModal}
                      className='text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2'
                    >
                      Close Modal
                    </button>
                    <button
                      type='button'
                      onClick={handleNext}
                      className='text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-primary-800 mr-2 mb-2'
                    >
                      Next
                    </button>
                  </div>
                </form>
              </div>
            )}
            {step === 2 && (
              <div>
                <form
                  className='space-y-4 md:space-y-6'
                  onSubmit={formik.handleSubmit}
                >
                  <ol className='items-center w-full sm:w-auto space-y-4 sm:flex sm:space-x-8 sm:space-y-0'>
                    <li className='flex items-center text-green-600 dark:text-green-500 space-x-2.5'>
                      <span className='flex items-center justify-center w-8 h-8 border border-green-600 rounded-full shrink-0 dark:border-green-500'>
                        2
                      </span>
                      <span>
                        <h4 className='font-medium leading-tight'>
                          Organization Details & Membership Type
                        </h4>
                        <p className='text-sm'>
                          Setup organization and set membership type
                        </p>
                      </span>
                    </li>
                  </ol>
                  {/* ... (Step 2 form fields) ... */}
                  <div className='container grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4'>
                    <div className='mb-6'>
                      <label
                        htmlFor='profileType'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        Select Membership Type
                      </label>
                      <select
                        name='profileType'
                        value={formik.values.profileType}
                        onChange={formik.handleChange}
                        required
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                      >
                        <option value=''>Choose membership type</option>
                        <option value='broker'>Broker</option>
                        <option value='warehouse_operator'>
                          Warehouse Operator
                        </option>
                        {/* <option value='cooperative'>Cooperative</option> */}
                        <option value='service_provider'>
                          Service Provider
                        </option>
                        <option value='investor'>Investor</option>
                        <option value='financial_institution'>
                          Financial Institution
                        </option>
                      </select>
                      {formik.touched.profileType &&
                        formik.errors.profileType && (
                          <p className='text-red-500 text-sm mt-1'>
                            {formik.errors.profileType}
                          </p>
                        )}
                    </div>
                    <div className='mb-6'>
                      <label
                        htmlFor='orgName-input'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        Registered Organization Name
                      </label>
                      <input
                        type='text'
                        name='orgName'
                        value={formik.values.orgName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                      />
                      {formik.touched.orgName && formik.errors.orgName && (
                        <p className='text-red-500 text-sm mt-1'>
                          {formik.errors.orgName}
                        </p>
                      )}
                    </div>
                    <div className='mb-6'>
                      <label
                        htmlFor='pacraRegNo-input'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        PACRA Registration No.
                      </label>
                      <input
                        type='text'
                        name='pacraRegNo'
                        value={formik.values.pacraRegNo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                      />
                      {formik.touched.pacraRegNo &&
                        formik.errors.pacraRegNo && (
                          <p className='text-red-500 text-sm mt-1'>
                            {formik.errors.pacraRegNo}
                          </p>
                        )}
                    </div>
                    <div className='mb-6'>
                      <label
                        htmlFor='zraTPIN-input'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        ZRA TPIN Number
                      </label>
                      <input
                        type='text'
                        name='zraTPIN'
                        value={formik.values.zraTPIN}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                      />
                      {formik.touched.zraTPIN && formik.errors.zraTPIN && (
                        <p className='text-red-500 text-sm mt-1'>
                          {formik.errors.zraTPIN}
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor='entity_type'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        Select Organization Category
                      </label>
                      <select
                        name='entity_type'
                        value={formik.values.entity_type}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                      >
                        <option value=''>Choose a category</option>
                        <option value='private'>Private Limited Company</option>
                        <option value='public'>Public Company</option>
                        <option value='cooperative'>Cooperative</option>
                        <option value='other'>Other</option>
                      </select>
                      {formik.touched.entity_type &&
                        formik.errors.entity_type && (
                          <p className='text-red-500 text-sm mt-1'>
                            {formik.errors.entity_type}
                          </p>
                        )}
                    </div>

                    <div>
                      <label
                        htmlFor='entity_email'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        Organization Email Address
                      </label>
                      <input
                        type='email'
                        name='entity_email'
                        value={formik.values.entity_email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                      />
                      {formik.touched.entity_email &&
                        formik.errors.entity_email && (
                          <p className='text-red-500 text-sm mt-1'>
                            {formik.errors.entity_email}
                          </p>
                        )}
                    </div>

                    <div>
                      <label
                        htmlFor='entity_phone'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        Organization Primary Phone No.
                      </label>
                      <PhoneInput
                        name='entity_phone'
                        country={'zm'}
                        value={formik.values.entity_phone}
                        onChange={handlePhoneChange}
                        onBlur={formik.handleBlur}
                        containerClass='w-full'
                        buttonClass='dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                        required
                      >
                        <div className='mb-2'></div>
                      </PhoneInput>
                      {formik.touched.entity_phone &&
                        formik.errors.entity_phone && (
                          <p className='text-red-500 text-xs mt-1'>
                            {formik.errors.entity_phone}
                          </p>
                        )}
                    </div>

                    <div>
                      <label
                        htmlFor='postal_address'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        Postal Address (P.O. Box)
                      </label>
                      <input
                        type='text'
                        name='postal_address'
                        value={formik.values.postal_address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                      />
                    </div>

                    <div>
                      <label
                        htmlFor='physical_address'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        Organization Street Address
                      </label>
                      <input
                        type='text'
                        name='physical_address'
                        value={formik.values.physical_address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                      />
                      {formik.touched.physical_address &&
                        formik.errors.physical_address && (
                          <p className='text-red-500 text-sm mt-1'>
                            {formik.errors.physical_address}
                          </p>
                        )}
                    </div>

                    {/* Country Dropdown */}
                    <div className='mb-6'>
                      <label
                        htmlFor='country'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        Select Country
                      </label>
                      <select
                        name='country'
                        value={formik.values.country}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                      >
                        <option value=''>Choose a country</option>
                        {countries.map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                      {formik.touched.country && formik.errors.country && (
                        <p className='text-red-500 text-sm mt-1'>
                          {formik.errors.country}
                        </p>
                      )}
                    </div>

                    {/* Province/State Input or Dropdown based on country */}
                    <div className='mb-6'>
                      <label
                        htmlFor='province'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                      >
                        Province / State
                      </label>
                      {formik.values.country === 'Zambia' ? (
                        <select
                          name='province'
                          value={formik.values.province}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                        >
                          <option value=''>Choose a province</option>
                          {provinces.Zambia.map((province, index) => (
                            <option key={index} value={province}>
                              {province}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type='text'
                          name='province'
                          value={formik.values.province}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                        />
                      )}
                      {formik.touched.province && formik.errors.province && (
                        <p className='text-red-500 text-sm mt-1'>
                          {formik.errors.province}
                        </p>
                      )}
                    </div>
                  </div>
                  {error && (
                    <div
                      className='p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400'
                      role='alert'
                    >
                      <span className='font-medium'>Error: </span>
                      {error}
                    </div>
                  )}
                  {loading && (
                    <div className='text-center'>
                      <div role='status'>
                        <svg
                          aria-hidden='true'
                          className='inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600'
                          viewBox='0 0 100 101'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                            fill='currentColor'
                          />
                          <path
                            d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                            fill='currentFill'
                          />
                        </svg>
                        <span className='sr-only'>Please wait...</span>
                      </div>
                    </div>
                  )}
                  {/* Close button */}
                  <div className='flex space-x-4'>
                    <button
                      type='button'
                      onClick={handlePrev}
                      className='text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2'
                    >
                      Previous
                    </button>
                    <button
                      type='submit'
                      disabled={!formik.isValid}
                      className='text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-primary-800 mr-2 mb-2'
                    >
                      {formik.isSubmitting
                        ? 'Registering Member...'
                        : 'Register Member'}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MembershipManagementDashboard;
