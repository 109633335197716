import React, { useEffect, useRef, useState } from 'react';
import NavLogo from '../../../../components/NavLogo';
import DynamicNavLogo from '../../../../components/DynamicNavLogo';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { mkConfig, generateCsv, download } from "export-to-csv";
import { bulkEntityCreationReset, bulk_entity_creation, retrieveAllUsers, retrieveAllUsersReset, retrieveEntityListData, retrieveEntityListReset } from '../../../../utils/actions';
import * as app_consts from '../../../../utils/constants';
import { EMAIL_REGEX, EMPTYNULLREGEX, ZAMIBAN_PHONE_NUMBER_REGEX, convertUnderscoreToTitleCase } from '../../../../utils/functionalUtils';
import districts from "../../../../utils/districts.json";
import TableComp from '../../../../components/table/TableComp';

const csv_headers = ["contact_name", "contact_number", "contact_email", "entity_name", "entity_email", "entity_mobile", "area", "district"];

const CooperativeEntityCSVImort = () => {

   const { entity_id, entity_mid } = useParams();
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const [filePreview, setFilePreview] = useState(null);
   const [selectedFile, setSelectedFile] = useState(null);

   const [error, setError] = useState("");
   const [loading, setLoading] = useState(false);
   const [loadingmessage, setLoadingMessage] = useState("");
   const [imported_data, setImportedData] = useState("");
   const [imported_data_list, setImportedDataList] = useState("");
   const [entity_data, setEntityData] = useState([]);
   const [user_data, setUserData] = useState([]);
   const [data_uploaded, setDataUploaded] = useState(false);
   const [data_verification, setDataVerification] = useState("");
   const [failed_imports, setFailedImports] = useState([]);
   const [successImports, setSuccessImports] = useState([]);
   const [completed, setCompleted] = useState(false);
   const [completed_success, setCompletedSuccess] = useState("");

   // Handle Logout
   const handleLogout = () => {
      localStorage.clear();
      navigate('/');
   };



   const export_to_csv = async (e) => {
      e.preventDefault();

      const options = mkConfig({
         fieldSeparator: ',',
         quoteStrings: '"',
         showLabels: true,
         showTitle: false,
         title: " ",
         useTextFile: false,
         useBom: true,
         filename: "zamace_cooperative_import_template",
         useKeysAsHeaders: false,
         columnHeaders: csv_headers
      });

      // // Export CSV
      const csv = generateCsv(options)([]);

      download(options)(csv);
   };

   const convertToJson = (csv_data) => {
      const lines = csv_data.split("\n");

      setLoadingMessage("Verifying Headers");

      // Extract the Column Headers
      const headers = lines[0]?.split(",")?.map(header => header.replace(/"/g, '')?.trim());
      // Validate the headers
      const headersMatch = csv_headers.every((header, index) => header === headers[index]);


      if (!headersMatch)
      {
         setError("Invalid headers");
         setLoading(false);
         setLoadingMessage("");
         setDataUploaded(false);
      } else
      {
         dispatch(retrieveEntityListData());
         dispatch(retrieveAllUsers());
         setLoadingMessage("Verifying CSV Data");
         const result = [];

         // Loop Through Each Line of CSV Data (Excluding the Headers)
         for (let i = 1; i < lines?.length; i++)
         {
            const currentLine = lines[i].split(",").map(field => field.trim());

            // Check if the row is empty or contains only whitespace
            if (currentLine.every(field => field === ""))
            {
               continue;
            }

            const obj = {};
            // Loop Through Each Column and Match the Headers
            for (let j = 0; j < headers.length; j++)
            {
               obj[headers[j]] = currentLine[j];
            }

            result.push(obj);
         }

         // Load Into State
         setImportedData(result);
         setImportedDataList(result);
      }
   };

   // Imported Data listener
   useEffect(() => {
      if (imported_data?.length > 0)
      {
         if (user_data?.length > 0 && entity_data?.length > 0 && error === "" && data_uploaded && data_verification === "")
         {
            verify_entity_details(imported_data[0]);
            // Retrieve All Users and All Entities
         }
      } else
      {
         if (data_uploaded)
         {
            if (data_verification === app_consts.COMPLETE)
            {
               setLoadingMessage("");
               setLoading(false);
            } else if (data_verification === "")
            {
               setLoading(false);
               setError("Upload Failed: No Data in the CSV Document has been found.");
            }
         }
      }
   }, [imported_data, user_data, entity_data, error, data_verification]);


   // Listen to User List Reducer
   const user_list_listner = useSelector((state) => state.getUserAccountList);
   // 
   useEffect(() => {
      if (user_list_listner.status !== app_consts.IDLE)
      {
         if (!user_list_listner.isLoading)
         {
            if (user_list_listner.error)
            {
               setError("Verification Failed: Could Not Verify Contact Details");
               setLoading(false);
            }
            else
            {
               setUserData(user_list_listner.data);
            }
            dispatch(retrieveAllUsersReset());
         }
      }
   }, [user_list_listner]);

   // Listen to Entity List Changes
   const entity_list_data_listener = useSelector((state) => state.retrieveEntityListData);

   useEffect(() => {
      if (entity_list_data_listener.status !== app_consts.IDLE)
      {
         if (!entity_list_data_listener.isLoading)
         {
            if (entity_list_data_listener.error)
            {
               setError("Verification Failed: Could Not Verify Entity Details");
            }
            else if (entity_list_data_listener.data)
            {
               setEntityData(entity_list_data_listener.data);
            }
            dispatch(retrieveEntityListReset());
         }
      }
   }, [entity_list_data_listener]);

   //  Verifying Data

   const handleFileChange = (event) => {
      setLoading(true);
      setLoadingMessage("Uploading CSV Document");
      setError("");
      setDataVerification("");
      setSuccessImports([]);
      setFailedImports([]);
      setDataUploaded(true);
      const file = event?.target?.files[0];
      const reader = new FileReader();


      reader.onload = (e) => {
         const csv_data = e?.target?.result;

         convertToJson(csv_data);

      };

      reader?.readAsText(file);
   };

   const verify_entity_details = (entity_details) => {

      let new_entity_details = entity_details;
      const errors = [];
      // Verify Area
      const valid_area = EMPTYNULLREGEX.test(new_entity_details?.area);
      if (valid_area)
      {
         errors.push("Cooperative Area/Village is Required.");
      }
      // Verify Contact Email
      const valid_contact_email = validate_contact_email(new_entity_details?.contact_email);
      if (!valid_contact_email?.status)
      {
         errors.push(valid_contact_email?.error);
      }
      else
      {
         if (new_entity_details?.contact_email === app_consts.NIL)
         {
            new_entity_details = { ...new_entity_details, contact_email: "" };
         }
      }

      // Verify Contact Number
      const valid_contact_number = verify_contact_number(new_entity_details?.contact_number);
      if (!valid_contact_number?.status)
      {
         errors.push(valid_contact_number?.error);
      }

      // Verify Contact Name
      const valid_contact_name = verify_contact_name(new_entity_details?.contact_name);
      if (!valid_contact_name?.status)
      {
         errors.push(valid_contact_name?.error);
      }
      else
      {
         new_entity_details = { ...new_entity_details, first_name: valid_contact_name?.first_name, middle_name: valid_contact_name?.middle_name, last_name: valid_contact_name?.last_name };
      }

      // 

      // Verify District
      const valid_district = EMPTYNULLREGEX.test(new_entity_details?.district);
      if (valid_district)
      {
         errors.push("District is Required.");
      }
      else
      {
         let province = [];
         // Filter DIstricts to Get Provinces
         for (const region in districts)
         {

            if (districts[region].map(district => district.toLowerCase()).includes(new_entity_details?.district))
            {
               province?.push(region);
            }
         }
         if (province?.length > 0)
         {
            new_entity_details = { ...new_entity_details, province: province[0] };
         }
         else
         {
            errors.push("Invalid District");
         }
      }

      // Verify Entity Email
      const valid_entity_email = validate_entity_email(new_entity_details?.entity_email);
      if (!valid_entity_email?.status)
      {
         errors.push(valid_entity_email?.error);
      }
      else
      {
         if (new_entity_details?.entity_email === app_consts.NIL)
         {
            new_entity_details = { ...new_entity_details, entity_email: "" };
         }
      }

      // Verify Entity Number 
      const valid_entity_mobile = verify_entity_mobile(new_entity_details?.entity_mobile);
      if (!valid_entity_mobile?.status)
      {
         errors.push(valid_entity_mobile?.error);
      }

      // Verify Entity Name 
      const valid_entity_name = verify_entity_name(new_entity_details?.entity_name);
      if (!valid_entity_name?.status)
      {
         errors.push(valid_entity_name?.error);
      }

      // Check for Duplicates
      const duplicate_1 = imported_data_list?.filter((data) => data?.entity_mobile === new_entity_details?.entity_mobile);

      if (duplicate_1?.length > 1)
      {
         errors.push("Duplicate Entity Mobile");
      }
      const duplicate_2 = imported_data_list?.filter((data) => data?.contact_number === new_entity_details?.contact_number);

      if (duplicate_2?.length > 1)
      {
         errors.push("Duplicate Contact Mobile");
      }

      const duplicate_3 = imported_data_list?.filter((data) => data?.contact_email === new_entity_details?.contact_email && new_entity_details?.contact_email !== app_consts.NIL);

      if (duplicate_3?.length > 1)
      {
         errors.push("Duplicate Contact Email");
      }
      const duplicate_4 = imported_data_list?.filter((data) => data?.entity_email === new_entity_details?.entity_email && new_entity_details?.entity_email !== app_consts.NIL);

      if (duplicate_4?.length > 1)
      {
         errors.push("Duplicate Entity Email");
      }

      let processedData = 0;
      if (errors?.length > 0)
      {
         // Add Errors to Failed Imports
         processedData = (failed_imports?.length + 1) + successImports?.length;
         new_entity_details = { ...new_entity_details, errors: errors };
         setFailedImports([...failed_imports, { ...new_entity_details }]);
      }
      else
      {
         processedData = (successImports?.length + 1) + failed_imports?.length;
         setSuccessImports([...successImports, { ...new_entity_details }]);
      }

      // Calculate Percentage progess
      const percentage = (processedData / imported_data_list?.length) * 100;
      setLoadingMessage(`Verifying CSV Data (${parseFloat(percentage?.toFixed(0))} %)`);

      // Update Import Data
      const updated_import_data = imported_data?.filter((detail, index) => index !== 0);

      if (processedData === imported_data_list?.length)
      {
         setDataVerification(app_consts.COMPLETE);
         setDataUploaded(true);
      }
      setImportedData(updated_import_data);

   };

   // Validate Contact Email
   const validate_contact_email = (contact_email) => {
      const valid_email = () => {
         let res = false;

         // Check if NIL
         if (contact_email === app_consts.NIL)
         {
            res = { status: true, contact_email: "" };
         }
         else
         {
            res = { status: true, contact_email: contact_email };
         }
         return res;
      };

      let return_res;
      const email_check = valid_email();

      if (email_check?.status)
      {
         // Check if Email Exists
         const email_exists = user_data?.filter((user) => user?.account_email?.toLowerCase() === contact_email?.toLowerCase());

         if (email_exists?.length === 0)
         {
            return_res = { status: true };
         }
         else
         {
            return_res = { status: false, error: "Contact Email Exists" };
         }
      }
      else if (!email_check?.status)
      {
         return_res = { status: false, error: "Invalid Contact Email. Insert a Valid Email Address. Email must be 'NIL' if Not Available." };
      }

      return return_res;
   };

   // Verify Contact Number
   const verify_contact_number = (contact_number) => {
      const phone_number_regex_check = ZAMIBAN_PHONE_NUMBER_REGEX.test(contact_number);
      let return_res;

      if (phone_number_regex_check)
      {
         // Check if Phone Number Exists
         const mobile_exists = user_data?.filter((user) => user?.account_mobile?.toLowerCase() === contact_number?.toLowerCase());
         let res;

         if (mobile_exists?.length === 0)
         {
            return_res = { status: true };
         }
         else
         {
            return_res = { status: false, error: "Contact Number Exists." };
         }
      }
      else
      {
         return_res = { status: false, error: "Invalid Contact Number. Should be a Valid Zambian Phone Number." };
      }
      return return_res;
   };

   // Verify Contact Name
   const verify_contact_name = (contact_name) => {
      let return_res;
      // Trim the name to remove any leading or trailing spaces
      const trimmedName = contact_name.trim();

      // Split the name by spaces
      const nameParts = trimmedName.split(" ");

      // Check for empty name or invalid input
      if (nameParts.length === 0 || trimmedName === "")
      {
         return_res = { status: false, error: "Contact Name is Required." };
      }
      else
      {
         // Extract the first, middle, and last names
         const first_name = nameParts[0];
         const last_name = nameParts.length > 1 ? nameParts[nameParts.length - 1] : null;
         const middle_name = nameParts.length > 2 ? nameParts.slice(1, -1).join(" ") : null;

         return_res = { status: true, first_name: first_name, last_name: last_name, middle_name: middle_name };
      }
      return return_res;
   };

   // Validate Entity Email
   const validate_entity_email = (entity_email) => {
      const valid_email = () => {
         let res = false;

         // Check if NIL
         if (entity_email === app_consts.NIL)
         {
            res = { status: true, entity_email: "" };
         }
         else
         {
            res = { status: true, entity_email: entity_email };
         }
         return res;
      };

      let return_res;
      const email_check = valid_email();

      if (email_check?.status)
      {
         // Check if Email Exists
         const email_exists = entity_data?.filter((entity) => entity?.entity_email?.toLowerCase() === entity_email?.toLowerCase());

         if (email_exists?.length === 0)
         {
            return_res = { status: true };
         }
         else
         {
            return_res = { status: false, error: "Entity Email Exists" };
         }
      }
      else if (!email_check?.status)
      {
         return_res = { status: false, error: "Invalid Entity Email. Insert a Valid Email Address. Email must be 'NIL' if Not Available." };
      }

      return return_res;
   };

   // Verify Entity Phone
   const verify_entity_mobile = (entity_mobile) => {
      const phone_number_regex_check = ZAMIBAN_PHONE_NUMBER_REGEX.test(entity_mobile);
      let return_res;

      if (phone_number_regex_check)
      {
         // Check if Phone Number Exists
         const mobile_exists = entity_data?.filter((entity) => entity?.entity_phone?.toLowerCase() === entity_mobile?.toLowerCase());

         if (mobile_exists?.length === 0)
         {
            return_res = { status: true };
         }
         else
         {
            return_res = { status: false, error: "Entity Number Exists." };
         }
      }
      else
      {
         return_res = { status: false, error: "Invalid Entity Number. Should be a Valid Zambian Phone Number." };
      }
      return return_res;
   };

   // Verify Entity Name
   const verify_entity_name = (entity_name) => {
      const valid_entity_name = EMPTYNULLREGEX.test(entity_name);
      let return_res;
      if (valid_entity_name)
      {
         return_res = { status: false, error: "Entity Name is Required." };
      }
      else
      {
         // Check if Entity Name Exists
         const entity_exists = entity_data?.filter((entity) => entity?.entity_name?.replace(/\s+/g, '')?.toLowerCase() === entity_name?.replace(/\s+/g, '')?.toLowerCase());
         if (entity_exists?.length === 0)
         {
            return_res = { status: true };
         }
         else
         {
            return_res = { status: false, error: "Entity Name Exists." };
         }
      }
      return return_res;
   };

   const fileInputRef = useRef(null);

   const handleButtonClick = () => {
      fileInputRef?.current?.click();
   };

   // Handle Data Import and Upload
   const handleDataImport = () => {
      dispatch(bulk_entity_creation({ bulk_data: successImports, type: "cooperative" }));
   };

   // Listen to Entity List Changes
   const bulk_entity_creation_listener = useSelector((state) => state.bulkEntityCreation);

   useEffect(() => {
      if (bulk_entity_creation_listener.status !== app_consts.IDLE)
      {
         if (bulk_entity_creation_listener.isLoading)
         {
            setLoading(true);
            setLoadingMessage("Submitting Data");
         } else
         {
            if (bulk_entity_creation_listener.error)
            {
               setError(bulk_entity_creation_listener.error);
            } else if (bulk_entity_creation_listener.data)
            {
               setEntityData(bulk_entity_creation_listener.data);
               console.log(bulk_entity_creation_listener.data);
               setCompleted(true);

               const succ_imports = bulk_entity_creation_listener?.data?.filter(
                  (imp_data) => imp_data?.import_message === "success"
               );

               const fail_imports = bulk_entity_creation_listener?.data?.filter(
                  (imp_data) => imp_data?.import_message === "fail"
               );

               setCompletedSuccess(
                  `Data Import Completed with ${succ_imports?.length} Successful Imports and ${fail_imports?.length} Failed Imports.`
               );

               const updated_failed_imports = fail_imports?.map((import_item) => {
                  return { ...import_item, errors: [import_item?.error_message] };
               });

               setFailedImports(updated_failed_imports);
            }

            setLoadingMessage("");
            setLoading(false);
            dispatch(bulkEntityCreationReset());
         }
      }
   }, [bulk_entity_creation_listener]);

   return (
      <>
         <button
            data-drawer-target='logo-sidebar'
            data-drawer-toggle='logo-sidebar'
            aria-controls='logo-sidebar'
            type='button'
            className='inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600'
         >
            <span className='sr-only'>Open sidebar</span>
            <svg
               className='w-6 h-6'
               aria-hidden='true'
               fill='currentColor'
               viewBox='0 0 20 20'
               xmlns='http://www.w3.org/2000/svg'
            >
               <path
                  clip-rule='evenodd'
                  fill-rule='evenodd'
                  d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
               ></path>
            </svg>
            <NavLogo />
         </button>

         <nav class="flex items-center justify-between flex-wrap p-6 bg-gray-50">
            <div class="flex items-center flex-shrink-0  mr-6">

               <a href='/home' className='flex items-center pl-2.5'>
                  <DynamicNavLogo height='50' width='150' />
               </a>
            </div>
            <div class="block lg:hidden">
               <button class="flex items-center px-3 py-2 border rounded hover:text-white hover:border-white">
                  <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
               </button>
            </div>
            <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
               <div class="text-sm lg:flex-grow">
                  <a href={`/exchange/${entity_id}/${entity_mid}/cooperative_management`} class="text-lg flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white font-semibold group">
                     Cooperative Management
                  </a>
               </div>
               <div>
                  <a href="#" onClick={handleLogout} class="inline-block text-sm px-4 py-2 leading-none border border-white hover:border-transparent  hover:bg-white mt-4 lg:mt-0 font-semibold">Logout</a>
               </div>
            </div>
         </nav>

         <div className="lg-full lg:w-10/12 m-auto">
            <div className="p-4 rounded-lg dark:border-gray-700">

               <div className="relative">
                  <div>
                     <h5 class="text-3xl mr-3 font-semibold dark:text-white text-gray-500">Import Cooperative Entity</h5>
                     <div>
                        <div class="flex flex-col items-start justify-start pt-5 pb-6">
                           <p class="mb-2 text-base text-gray-500 dark:text-gray-400"><span class="font-semibold">Please Attach a CSV Document to Begin the Importation Process.</span></p>
                           <p class="text-xs text-gray-500 dark:text-gray-400">
                              <p class="text-base text-gray-500 dark:text-gray-400">Click <span className='cursor-pointer text-blue-600 underline font-bold' onClick={export_to_csv}>Here</span> to Download a Template of the Document to be Uploaded.</p>
                           </p>
                        </div>
                        {
                           data_verification === app_consts.COMPLETE && (
                              <>
                                 {
                                    !completed && (
                                       <>
                                          <div class="flex flex-row justify-between w-full">
                                             <div>
                                                <button className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-600 hover:bg-green-800 focus:ring-4 focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800" type="button" onClick={handleButtonClick} disabled={loading} >

                                                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 mr-2">
                                                      <path stroke-linecap="round" stroke-linejoin="round" d="M9 3.75H6.912a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859M12 3v8.25m0 0-3-3m3 3 3-3" />
                                                   </svg>


                                                   Re-upload CSV
                                                </button>
                                                <input
                                                   id="dropzone-file"
                                                   type="file"
                                                   accept=".csv"
                                                   className="hidden"
                                                   ref={fileInputRef}
                                                   onChange={(event) => handleFileChange(event)}
                                                />
                                             </div>
                                             <div>
                                                <button className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-600 hover:bg-green-800 focus:ring-4 focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800" type="button" onClick={handleDataImport} disabled={loading}>
                                                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 mr-2">
                                                      <path stroke-linecap="round" stroke-linejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12" />
                                                   </svg>

                                                   Submit and Save Data
                                                </button>
                                             </div>
                                          </div>
                                       </>
                                    )
                                 }
                              </>
                           )
                        }
                     </div>

                     {
                        loading ? (
                           <>
                              <div className="text-center my-2">
                                 <div className="text-center my-6">
                                    <span colSpan="8" className="text-gray-700 py-4 my-2">
                                       {loadingmessage}
                                    </span>
                                 </div>
                                 <div role="status">
                                    <svg
                                       aria-hidden="true"
                                       className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                                       viewBox="0 0 100 101"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                    >
                                       <path
                                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                          fill="currentColor"
                                       />
                                       <path
                                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                          fill="currentFill"
                                       />
                                    </svg>
                                    <span className="sr-only">Please wait...</span>
                                 </div>
                              </div>
                           </>
                        )
                           : (
                              <>
                                 {error && (
                                    <div
                                       className='p-4 my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400'
                                       role='alert'
                                    >
                                       <span className='font-medium'>Error: </span>
                                       {error}
                                    </div>
                                 )
                                 }
                                 {
                                    completed ?
                                       <>
                                          <div className="text-center my-2 flex flex-col items-center justify-center">
                                             <div className="text-center my-6">
                                                <span colSpan="8" className="text-gray-700 py-4 my-2">
                                                   {completed_success}
                                                </span>
                                             </div>
                                             <div role="status">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-green-600 inline w-8 h-8 mr-2  dark:text-gray-600  ">
                                                   <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                </svg>

                                                <span className="sr-only">Please wait...</span>
                                             </div>
                                             <button onClick={() => navigate(`/exchange/${entity_id}/${entity_mid}/cooperative_management`)} className="mt-4 flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-green-600 hover:bg-green-800 focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800 px-8 py-2" type="button" disabled={loading}>

                                                Finish
                                             </button>
                                          </div>
                                          <div className='my-4'>
                                             <div>
                                                <span className='text-xl font-semibold text-gray-700'>Failed Submissions</span>
                                             </div>
                                             <div>
                                                <TableComp tableData={failed_imports} tableType={app_consts.INVALID_COOPERATIVE_ENTITY_CSV_DATA} tableHeading={"Failed Submissions"} />
                                             </div>
                                          </div>
                                       </>
                                       :
                                       <>
                                          {
                                             data_verification === app_consts.COMPLETE ?
                                                <>
                                                   <div>
                                                      {
                                                         successImports && (
                                                            <>
                                                               <div className='my-4'>
                                                                  <div>
                                                                     <span className='text-xl font-semibold text-gray-700'>Valid Data (To be Imported)</span>
                                                                  </div>
                                                                  <div>
                                                                     <TableComp tableData={successImports} tableType={app_consts.VALID_COOPERATIVE_ENTITY_CSV_DATA} tableHeading={"Valid Data"} />
                                                                  </div>
                                                               </div>
                                                            </>
                                                         )
                                                      }
                                                      {
                                                         failed_imports && (
                                                            <>
                                                               <div className='my-4'>
                                                                  <div>
                                                                     <span className='text-xl font-semibold text-gray-700'>Invalid Data (Not to be Imported)</span>
                                                                  </div>
                                                                  <div>
                                                                     <TableComp tableData={failed_imports} tableType={app_consts.INVALID_COOPERATIVE_ENTITY_CSV_DATA} tableHeading={"Invalid Data"} />
                                                                  </div>
                                                               </div>
                                                            </>
                                                         )
                                                      }
                                                   </div>
                                                </>
                                                :
                                                <>
                                                   <div class="flex items-center justify-center w-full mt-8">
                                                      <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-96 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                         <div>
                                                            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                                               <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                                               </svg>
                                                               <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span></p>
                                                               <p class="text-xs text-gray-500 dark:text-gray-400"> CSV File Only</p>
                                                            </div>
                                                            <input
                                                               id="dropzone-file"
                                                               type="file"
                                                               accept=".csv"
                                                               className="hidden"
                                                               onChange={handleFileChange}
                                                            />
                                                         </div>
                                                      </label>
                                                   </div>
                                                </>
                                          }
                                       </>
                                 }
                              </>
                           )}
                  </div>
               </div>
            </div>
         </div>

      </>
   );
};

export default CooperativeEntityCSVImort;
















