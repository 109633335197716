import React, { useEffect, useState } from 'react';
import DynamicNavLogo from '../../../components/DynamicNavLogo';
import NavLogo from '../../../components/NavLogo';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomTabs from '../../../layouts/CustomTabs';
import { retrieveEntityProfileInfoReset, retrieve_entity_profile_info } from '../../../utils/actions';
import * as app_consts from '../../../utils/constants';

const ExchangeProfiles = () => {

 const { entity_id, entity_mid } = useParams();
 const navigate = useNavigate();
 const dispatch = useDispatch();

 const [user_profiles_loading, setUserProfilesLoading] = useState(true);
 const [user_profiles_error, setUserProfilesError] = useState("");
 const [audit_trails_loading, setAuditTrailsLoading] = useState(true);
 const [audit_trails_error, setAuditTrailsError] = useState("");

 const [entity_profile_info, setEntityProfileInfo] = useState([]);

 // First Code to Run When Page Loads
 useEffect(() => {
  dispatch(retrieve_entity_profile_info({ entity_id: entity_id, entity_type: app_consts.EXCHANGE.toLowerCase() }));
 }, []);

 // Handle Logout
 const handleLogout = () => {
  localStorage.clear();
  navigate('/');
 };

 const tabs = [
  { title: 'User Profiles', disabled: user_profiles_loading },
  { title: 'Audit Trails', disabled: audit_trails_loading }
 ];

 const [activeTab, setActiveTab] = useState('User Profiles');

 // Listen to Entity Profile Info Params Reducer
 const entity_profile_info_listner = useSelector((state) => state.retrieveEntityProfileInfo);
 // 
 useEffect(() => {
  if (entity_profile_info_listner.status !== app_consts.IDLE)
  {
   if (entity_profile_info_listner.isLoading)
   {
    setUserProfilesLoading(true);
   }
   else
   {
    setUserProfilesLoading(false);
    if (entity_profile_info_listner.error)
    {
     setUserProfilesError(entity_profile_info_listner.error);
    }
    else
    {
     console.log(entity_profile_info_listner.data);
     setEntityProfileInfo(entity_profile_info_listner.data);
    }
    dispatch(retrieveEntityProfileInfoReset());
   }
  }
 }, [entity_profile_info_listner]);

 return (
  <>
   <button
    data-drawer-target='logo-sidebar'
    data-drawer-toggle='logo-sidebar'
    aria-controls='logo-sidebar'
    type='button'
    className='inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600'
   >
    <span className='sr-only'>Open sidebar</span>
    <svg
     className='w-6 h-6'
     aria-hidden='true'
     fill='currentColor'
     viewBox='0 0 20 20'
     xmlns='http://www.w3.org/2000/svg'
    >
     <path
      clip-rule='evenodd'
      fill-rule='evenodd'
      d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
     ></path>
    </svg>
    <NavLogo />
   </button>

   <nav class="flex items-center justify-between flex-wrap p-6 bg-gray-50">
    <div class="flex items-center flex-shrink-0  mr-6">

     <a href='/home' className='flex items-center pl-2.5'>
      <DynamicNavLogo height='50' width='150' />
     </a>
    </div>
    <div class="block lg:hidden">
     <button class="flex items-center px-3 py-2 border rounded hover:text-white hover:border-white">
      <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
     </button>
    </div>
    <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
     <div class="text-sm lg:flex-grow">
      <a href={`/exchange/${entity_id}/${entity_mid}`} class="text-lg flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white  group font-semibold">
       Exchange Dashboard
      </a>
     </div>
     <div>
      <a href="#" onClick={handleLogout} class="inline-block text-sm px-4 py-2 leading-none border border-white hover:border-transparent  hover:bg-white mt-4 lg:mt-0">Logout</a>
     </div>
    </div>
   </nav>

   <div className="lg-full lg:w-10/12 m-auto">
    <div className="p-4 rounded-lg dark:border-gray-700">

     <div className="relative">
      <div>
       <h5 class="text-3xl mr-3 font-semibold dark:text-white">User Profiles and Audit Trails</h5>
      </div>
      <CustomTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
     </div>
    </div>
   </div>
  </>
 );
};

export default ExchangeProfiles;