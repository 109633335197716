import React, { useEffect, useState } from 'react';
import {
  addNewBrokerSubOffer,
  getProducers,
  retrieveBrokerProducersReset,
  retrieve_broker_producers,
  getProducersAndWarehouseReceiptsForBroker,
  getProducersAndWarehouseReceiptsForBrokerReset,
} from '../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { format } from 'date-fns';
import RSelect from 'react-select';
import Select from 'react-select';
import * as app_consts from '../../utils/constants';
import {
  rSelectMapFun,
  retreive_rSelectVariables,
} from '../../utils/functionalUtils';

const CreateOfferModal = ({ showModal, closeModal, offer, commodityOrder }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { entity_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const entities = useSelector((state) => state.entityList.data);
  const fetchedTotalEntities = useSelector((state) => state.entityList.total);
  const [broker_producers, setBrokerProducers] = useState([]);

  const [currentReceipts, setCurrentReceipts] = useState([]);
  const [currentProducers, setCurrentProducers] = useState([]);
  const [producersData, setProducersData] = useState([]);
  const [warehouseReceiptsData, setWarehouseReceiptsData] = useState([]);
  const [warehousesData, setWarehousesData] = useState([]);

  // console.log("offer: ", offer.commodity);

  const initialValues = {
    bid_price: '',
    bid_volume: '',
    date_to_be_withdrawn_from_platform: '',
    bid_owner: '',
    producer_id: '',
    receipt_number: '',
  };

  const validationSchema = yup.object().shape({
    bid_price: yup.string().required('Offer Price is required'),
    bid_volume: yup.string().required('Offer Volume is required'),
    date_to_be_withdrawn_from_platform: yup
      .string()
      .required('Date to be withdrawn from platform is required'),
    offer_owner: yup.string().required('Offer Owner is required'),
    offer_owner: yup.string().required('Offer Owner is required'),
    receipt_number: yup.string().required('Warehouse Receipt is required'),
    offer_owner: yup.string().required('Offer Owner is required'),
    receipt_number: yup.string().required('Warehouse Receipt is required')
  });

  // useEffect(() => {
  //   console.log(showModal);
  //   if (showModal === true) 
  //   {
  //     fetchProducers();
  //   }
  // }, [showModal]);

  // const fetchProducers = () => {
  //   const account = localStorage.getItem('account')
  //     ? JSON.parse(localStorage.getItem('account'))
  //     : {};
  //   const brokerProfile = account.profiles.find(
  //     (profile) => profile.type === 'broker'
  //   );
  //   const brokerId = brokerProfile?.entity_id;

  //   setLoading(true);
  //   dispatch(getProducers(brokerId, /* currentPage, */ /* pageSize */)).then((response) => {
  //     setLoading(false);
  //     // Set totalEntities to the value from the API response
  //     // setTotalProducers(response.total);
  //   });
  // };

  // Retrieve Broker Producers
  useEffect(() => {
    dispatch(retrieve_broker_producers(entity_id));
  }, []);

  // Listen to Broker Producers State

  const retrieveBrokerProducer = useSelector(
    (state) => state.retrieveBrokerProducer
  );
  //
  useEffect(() => {
    if (retrieveBrokerProducer.status !== app_consts.IDLE)
    {
      if (retrieveBrokerProducer.isLoading)
      {
      } else
      {
        if (retrieveBrokerProducer.error)
        {
          console.log(retrieveBrokerProducer.error);
        } else
        {
          filterDepositors(retrieveBrokerProducer.data);
        }
        dispatch(retrieveBrokerProducersReset());
      }
    }
  }, [retrieveBrokerProducer]);

  const producersAndReceipts = useSelector(
    (state) => state.getProducersAndWarehouseReceiptsList
  );

  useEffect(() => {
    // const account = JSON.parse(localStorage.getItem('account')) || {};
    // const brokerProfile = account.profiles.find(
    //   (profile) => profile.type === 'broker'
    // );
    // const brokerIdEntity = brokerProfile.entity_id;

    dispatch(getProducersAndWarehouseReceiptsForBroker(entity_id));
  }, []);

  useEffect(() => {
    if (producersAndReceipts.status !== app_consts.IDLE)
    {
      if (producersAndReceipts.isLoading)
      {
        setLoading(true);
      } else
      {
        if (producersAndReceipts.error)
        {
          setError(producersAndReceipts.error);
        } else
        {
          setProducersData(producersAndReceipts?.data?.producers);
          setWarehouseReceiptsData(
            producersAndReceipts?.data?.issued_warehouse_receipts
          );
          setWarehousesData(producersAndReceipts?.data?.warehouses);
          dispatch(getProducersAndWarehouseReceiptsForBrokerReset());
          setLoading(false);
        }
      }
    }
  }, [producersAndReceipts]);

  const producerOptions = Array.from(new Set(currentProducers?.map(producer => producer.producer_id)))
    .map(producerId => {
      const producer = currentProducers.find(p => p.producer_id === producerId);
      return {
        value: producer,
        label: `${producer.producer_id} - ${producer.contact_name}`,
      };
    });


  const receiptNumberOptions = currentReceipts?.map((warehouseReceipt) => ({
    value: warehouseReceipt.receipt_number,
    label: warehouseReceipt.receipt_number,
  }));

  const handleChange = (fieldName, selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : '';
    formik.setFieldValue(fieldName, selectedValue);

    const account = JSON.parse(localStorage.getItem('account')) || {};
    const brokerProfile = account.profiles.find(
      (profile) => profile.type === 'broker'
    );
    const brokerAccountId = brokerProfile.account_id;

    if (fieldName === 'offer_owner')
    {
      if (selectedValue === 'client' || selectedValue === 'own')
      {
        const filteredProducers = producersData?.filter((producer) => {
          if (selectedValue === 'client')
          {
            return (
              producer.type === 'individual' ||
              !(producer.type === app_consts.BROKER.toLowerCase() || producer.type === app_consts.WAREHOUSE_OPERATOR.toLowerCase()) ||
              (producer.entity_id !== entity_id)
            );
          }

          if (selectedValue === 'own')
          {
            return (
              producer.entity_id === entity_id &&
              (producer.type === app_consts.WAREHOUSE_OPERATOR.toLowerCase() || producer.type === app_consts.BROKER.toLowerCase())
            );
          }

          return false;
        });


        if (selectedValue === 'client' && filteredProducers.length === 0)
        {
          setError('No clients with issued warehouse receipts available.');
        } else
        {
          setError('');
        }
        // console.log("filteredProducers: ", filteredProducers)
        // console.log("producersData: ", producersData)
        setCurrentProducers(filteredProducers);
      } else
      {
        setCurrentProducers([]);
        setError('');
      }
    }

    if (fieldName === 'producer_id')
    {
      setCurrentReceipts(
        warehouseReceiptsData?.filter(
          (warehouse_receipt) => warehouse_receipt.owner_id === selectedValue?.producer_id
        )
      );
    }

    if (fieldName === 'receipt_number')
    {
      const currentReceipt = warehouseReceiptsData.find(
        (warehouse_receipt) =>
          warehouse_receipt.receipt_number === selectedValue
      );

      const currentWarehouse = warehousesData.find(
        (warehouse) => warehouse.id === currentReceipt?.warehouse_id
      );

      formik.setFieldValue(
        'warehouse_id',
        currentWarehouse ? currentWarehouse?.id : ''
      );
    }
  };

  // Create a useFormik instance with the form configuration
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try
      {
        const timestamp = new Date();
        const formattedTimestamp = format(timestamp, 'MMMM dd, yyyy hh:mm a');
        const historyStatement = `Created new offer on ${formattedTimestamp}`;

        const account = localStorage.getItem('account')
          ? JSON.parse(localStorage.getItem('account'))
          : {};
        const brokerProfile = account.profiles.find(
          (profile) => profile.type === 'broker'
        );
        const brokerId = brokerProfile.id;
        const brokerName = brokerProfile.name;
        const accountId = brokerProfile.account_id;

        const offer_on_bid = {
          offer: values.bid_price,
          offer_volume: values.bid_volume,
          offer_owner: values.offer_owner,
          offer_total: values.bid_total,
          offer_producer_id: values.producer_id?.id,
          date_to_be_withdrawn_from_platform:
            values.date_to_be_withdrawn_from_platform,
          broker_id: brokerId,
          account_id: accountId,
          broker_name: brokerName,
          date_to_be_placed_on_platform: offer?.date_to_be_placed_on_platform,
          commodity: offer?.commodity,
          unit_of_measure: offer?.unit_of_measure,
          volume: offer?.volume,
          requested_unit_price: offer?.requested_unit_price,
          quality: offer?.quality,
          other_documentation_required: offer?.other_documentation_required,
          order_type: offer?.order_type,
          special_terms_and_conditions: offer?.special_terms_and_conditions,
          history: [historyStatement],
          order_number: offer?.order_number,
          date_submitted_to_platform: offer?.date_submitted_to_platform,
          location: offer?.location,
          delivery: offer?.delivery,
          receipt_number: values.receipt_number,
          warehouse_receipt_id: offer?.warehouse_receipt_id,
          commodity_order_id: commodityOrder?.id,
          order_number: commodityOrder?.order_number,
          status: 'open',
        };

        setLoading(true);

        setError('');

        await dispatch(addNewBrokerSubOffer({ offer_on_bid }));

        formik.resetForm();
        closeModal();
      } catch (error)
      {
        console.log(error.response);
        if (error.response)
        {
          if (error.response.status === 400)
          {
            setError(error.response.data.message);
          } else if (error.response.status === 500)
          {
            setError('Failed to Create Bid');
          }
        } else
        {
          setError('Failed to Create Bid');
        }
      } finally
      {
        setLoading(false);
      }
    },
  });

  const handleOnBlur = (name, value) => {
    formik.setFieldValue(name, parseFloat(value).toFixed(2));

    // calculate bid_total when bid_price or bid_volume changes
    if (name === 'bid_price' || name === 'bid_volume')
    {
      const bidAmount = parseFloat(formik.values.bid_price);
      const bidVolume = parseFloat(formik.values.bid_volume);
      const bidTotal = parseFloat(bidAmount * bidVolume);
      formik.setFieldValue('bid_total', bidTotal.toFixed(2));
    }
  };

  // Handle Changes in React Select Field
  const handlRSelectChanges = (option, name) => {
    if (option)
    {
      formik.setFieldValue(name, option.value);
    } else
    {
      formik.setFieldValue(name, '');
    }
  };

  const filterDepositors = async (producerList) => {
    let mappedData = [];

    await Promise.all(
      producerList?.data?.map(async (data_to_map) => {
        const { label, value } = retreive_rSelectVariables(
          app_consts.PRODUCERS_LIST_MAP,
          data_to_map
        );
        const result = await rSelectMapFun(label, value);
        mappedData.push(result);
      })
    );

    setBrokerProducers(mappedData);
    return mappedData;
  };

  return (
    <>
      {showModal ? (
        <div className='fixed inset-0 flex items-center justify-center z-50 overflow-y-auto'>
          <div className='fixed inset-0 bg-black opacity-50'></div>
          <div className='relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 md:p-8 max-h-screen overflow-y-auto'>
            <div class='flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600'>
              <h3 class='text-lg font-semibold text-gray-900 dark:text-white'>
                Place Offer
              </h3>
              <button
                type='button'
                onClick={() => closeModal()}
                class='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
                data-modal-toggle='updateProductModal'
              >
                <svg
                  aria-hidden='true'
                  class='w-5 h-5'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                    clip-rule='evenodd'
                  ></path>
                </svg>
                <span class='sr-only'>Close</span>
              </button>
            </div>

            <form
              className='space-y-4 md:space-y-6'
              onSubmit={formik.handleSubmit}
            >
              <ol className='items-center w-full sm:w-auto space-y-4 sm:flex sm:space-x-8 sm:space-y-0'>
                <li className='flex items-center text-green-600 dark:text-green-500 space-x-2.5'>
                  <span className='flex items-center justify-center w-8 h-8 border border-green-600 rounded-full shrink-0 dark:border-green-500'>
                    1
                  </span>
                  <span>
                    <h4 className='font-medium leading-tight'>
                      Offer Placement Form
                    </h4>
                    <p className='text-sm'>
                      Provide the your offer amount in the form below
                    </p>
                  </span>
                </li>
              </ol>
              <div className='container grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
                <div>
                  <label
                    htmlFor='bid_price'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Offer Price
                  </label>
                  <input
                    step='0.01'
                    type='number'
                    min='0.00'
                    default='0.00'
                    name='bid_price'
                    value={formik.values.bid_price}
                    onChange={formik.handleChange}
                    onBlur={() =>
                      handleOnBlur('bid_price', formik.values.bid_price)
                    }
                    required
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.bid_price && formik.errors.bid_price && (
                    <p className='text-red-500 text-sm mt-1'>
                      {formik.errors.bid_price}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor='bid_volume'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Offer Volume {formik.values.commodity?.toLowerCase() === 'gold' ? 'Grams (g)' : '(Metric Tonnes)'}
                  </label>
                  <input
                    step='0.01'
                    type='number'
                    min='0.00'
                    default='0.00'
                    name='bid_volume'
                    value={formik.values.bid_volume}
                    onChange={formik.handleChange}
                    onBlur={() =>
                      handleOnBlur('bid_volume', formik.values.bid_volume)
                    }
                    required
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.bid_volume && formik.errors.bid_volume && (
                    <p className='text-red-500 text-sm mt-1'>
                      {formik.errors.bid_volume}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor='date_to_be_withdrawn_from_platform'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Date to be withdrawn from platform
                  </label>
                  <input
                    type='date'
                    name='date_to_be_withdrawn_from_platform'
                    value={formik.values.date_to_be_withdrawn_from_platform}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.date_to_be_withdrawn_from_platform &&
                    formik.errors.date_to_be_withdrawn_from_platform && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.date_to_be_withdrawn_from_platform}
                      </p>
                    )}
                </div>

                <div>
                  <label
                    htmlFor='bid_total'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Offer Total
                  </label>
                  <input
                    type='text'
                    name='bid_total'
                    value={formik.values.bid_total}
                    disabled
                    className='bg-gray-200 cursor-not-allowed border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                </div>

                <div>
                  <label
                    htmlFor='nationality'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Offer Owner
                  </label>
                  <select
                    name='offer_owner'
                    value={formik.values.offer_owner}
                    onChange={(e) => {
                      handleChange('offer_owner', e.target);
                      formik.setFieldValue('offer_owner', e.target.value);
                      formik.setFieldValue('producer_id', '');
                    }}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                    required
                  >
                    <option value=''>Select An Option</option>
                    <option value='client'>Client</option>
                    <option value='own'>Own</option>
                  </select>
                  {formik.touched.offer_owner && formik.errors.offer_owner && (
                    <p className='text-red-500 text-sm mt-1'>
                      {formik.errors.offer_owner}
                    </p>
                  )}
                </div>
                {/* {formik.values.offer_owner === 'client' && ( */}
                <>
                  <div>
                    <label
                      htmlFor='producer_id'
                      className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                    >
                      Offer Made on Behalf of?
                    </label>
                    <Select
                      isClearable={true}
                      name='producer_id'
                      value={producerOptions?.find(
                        (option) => option.value === formik.values.producer_id
                      )}
                      onBlur={formik.handleBlur}
                      onChange={(selectedOption) =>
                        handleChange('producer_id', selectedOption)
                      }
                      options={producerOptions}
                    />
                  </div>
                </>
                {/* )} */}
                <div>
                  <label
                    htmlFor='receipt_number'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Warehouse Receipt Number
                  </label>

                  <Select
                    isClearable={true}
                    name='receipt_number'
                    value={receiptNumberOptions?.find(
                      (option) => option.value === formik.values.receipt_number
                    )}
                    onBlur={formik.handleBlur}
                    // onChange={(selectedOption) =>
                    //   handleChange('receipt_number', selectedOption)
                    // }
                    onChange={(e) =>
                      handlRSelectChanges(e, 'receipt_number')
                    }
                    options={receiptNumberOptions}
                  />

                  {formik.touched.receipt_number &&
                    formik.errors.receipt_number && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.receipt_number}
                      </p>
                    )}
                </div>
              </div>

              <div className='flex space-x-4'>
                <button
                  type='button'
                  onClick={closeModal}
                  className='text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2'
                >
                  Close
                </button>
                <button
                  type='submit'
                  disabled={!formik.isValid}
                  className='text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-primary-800 mr-2 mb-2'
                >
                  {formik.isSubmitting ? 'Placing Offer...' : 'Place Offer'}
                </button>
              </div>
              {error && (
                <div
                  className='p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400'
                  role='alert'
                >
                  <span className='font-medium'>Error: </span>
                  {error}
                </div>
              )}
              {loading && (
                <div className='text-center'>
                  <div role='status'>
                    <svg
                      aria-hidden='true'
                      className='inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600'
                      viewBox='0 0 100 101'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                        fill='currentColor'
                      />
                      <path
                        d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                        fill='currentFill'
                      />
                    </svg>
                    <span className='sr-only'>Please wait...</span>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CreateOfferModal;